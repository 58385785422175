import { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  Form,
  FormGroup,
  Input,
} from "reactstrap";
import { ToastContainer } from "react-toastify";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "../../assets/scss/datatables.scss";
import { getProductLog } from "../../service/parse-service";
import { Calendar } from "react-date-range";
import SalesReportTable from "./components/SalesReportTable";
import TablePagination from "../../components/TablePagination";

const SalesReport = ({ history }) => {
  const [loading, setLoading] = useState(false);
  const [dropdownOpenSalesStatus, setDropdownOpenSalesStatus] = useState(false);
  const [salesStatusOption, setSalesStatusOption] = useState("All");
  const [startTime, setStartTime] = useState(new Date("2023-01-01"));
  const [endTime, setEndTime] = useState(new Date());
  const [dropdownOpenDateRange, setDropdownOpenDateRange] = useState(false);
  const [productLogData, setProductLogData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [order, setOrder] = useState({
    column: "",
    direction: "",
  });
  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [productLogPerPage] = useState(10);
  const [totalProductLog, setTotalProductLog] = useState(0);

  const handleOrder = (column) => {
    let direction = "asc";
    if (order.column === column && order.direction === "asc") {
      direction = "desc";
    }
    setOrder({ column, direction });
  };

  useEffect(() => {
    const fetchProductLog = async () => {
      setLoading(true);

      let results = await getProductLog(
        startTime,
        endTime,
        searchQuery,
        productLogPerPage,
        currentPage,
        salesStatusOption,
        order
      );

      if (currentPage > 1 && results.items.length === 0) {
        setCurrentPage(1);
      }

      setTotalProductLog(results.totalCount);
      setProductLogData(results.items);

      setLoading(false);
    };

    fetchProductLog();
  }, [
    salesStatusOption,
    startTime,
    endTime,
    searchQuery,
    productLogPerPage,
    currentPage,
    order,
  ]);

  // useEffect(() => {
  //   (async () => {
  //     setLoading(true);
  //     try {
  //       let mappedResult = [];
  //       const productLogData = await getProductLog(startTime, endTime);

  //       mappedResult = productLogData?.map((productLog) => ({
  //         fullname: productLog.user?.nickname,
  //         date: new Date(productLog.createdAt).toLocaleString(),
  //         salesStatus: productLog.productType,
  //         salesAmount: `$ ${productLog.price}`,
  //       }));
  //       mappedResult = mappedResult.filter((item) => item);

  //       let salesStatusData = [];
  //       mappedResult.forEach((productLog) => {
  //         if (!salesStatusData.includes(productLog.salesStatus)) {
  //           salesStatusData.push(productLog.salesStatus);
  //         }
  //       });

  //       setSalesStatusData(salesStatusData);

  //       if (salesStatusOption !== "All") {
  //         mappedResult = mappedResult.filter(
  //           (item) => item.salesStatus === salesStatusOption
  //         );
  //       }

  //       setData((prevState) => ({
  //         ...prevState,
  //         rows: mappedResult,
  //       }));
  //     } catch (e) {
  //       console.log(e);
  //     } finally {
  //       setLoading(false);
  //     }
  //   })();
  // }, [salesStatusOption, startTime, endTime]);

  const handleSalesStatusToggle = () =>
    setDropdownOpenSalesStatus(!dropdownOpenSalesStatus);

  const handleReset = () => {
    setSalesStatusOption("All");
    setStartTime(new Date("2023-01-01"));
    setEndTime(new Date());
    setOrder({
      column: "",
      direction: "",
    });
    setSearchQuery("");
  };

  const handleDateSelect = (value) => {
    setStartTime(value);
    setEndTime(new Date(value.getTime() + 60 * 60 * 1000 * 24));
  };

  const handleTimeSelect = (option) => {
    switch (option) {
      case "oneHour":
        setStartTime(new Date(new Date().getTime() - 60 * 60 * 1000));
        break;
      case "sixHour":
        setStartTime(new Date(new Date().getTime() - 60 * 60 * 1000 * 6));
        break;
      case "twelveHour":
        setStartTime(new Date(new Date().getTime() - 60 * 60 * 1000 * 12));
        break;
      case "oneDay":
        setStartTime(new Date(new Date().getTime() - 60 * 60 * 1000 * 24));
        break;
      default:
        break;
    }
  };

  const handleDateRangeToggle = () =>
    setDropdownOpenDateRange(!dropdownOpenDateRange);

  return (
    <>
      <ToastContainer />
      <div className="page-content">
        <Breadcrumbs title="Dashboard" breadcrumbItem="Sales Report" />
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <CardTitle className="mb-3">Sales Report</CardTitle>
                <div className="d-flex justify-content-start mb-3">
                  <Dropdown
                    className="me-2"
                    isOpen={dropdownOpenDateRange}
                    toggle={handleDateRangeToggle}
                  >
                    <DropdownToggle color="primary">
                      {`${startTime.toLocaleDateString()} - ${endTime.toLocaleDateString()}`}{" "}
                      <i className="fas fa-caret-down"></i>
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem onClick={() => handleTimeSelect("oneHour")}>
                        Last 1 hours
                      </DropdownItem>
                      <DropdownItem onClick={() => handleTimeSelect("sixHour")}>
                        Last 6 hours
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => handleTimeSelect("twelveHour")}
                      >
                        Last 12 hours
                      </DropdownItem>
                      <DropdownItem onClick={() => handleTimeSelect("oneDay")}>
                        Last 24 hours
                      </DropdownItem>
                      <Calendar
                        minDate={new Date(`01-01-${new Date().getFullYear()}`)}
                        maxDate={new Date(`12-31-${new Date().getFullYear()}`)}
                        date={new Date()}
                        onChange={handleDateSelect}
                      />
                    </DropdownMenu>
                  </Dropdown>
                  <Dropdown
                    className="me-2"
                    isOpen={dropdownOpenSalesStatus}
                    toggle={handleSalesStatusToggle}
                  >
                    <DropdownToggle color="primary">
                      Sales Status <i className="fas fa-caret-down"></i>
                    </DropdownToggle>
                    <DropdownMenu className="px-2">
                      <DropdownItem
                        className="px-0"
                        onClick={() => setSalesStatusOption("All")}
                      >
                        Show All
                      </DropdownItem>

                      <DropdownItem
                        className="px-0"
                        onClick={() => setSalesStatusOption("android-inapp")}
                      >
                        android-inapp
                      </DropdownItem>
                      <DropdownItem
                        className="px-0"
                        onClick={() => setSalesStatusOption("ios-inapp")}
                      >
                        ios-inapp
                      </DropdownItem>
                      <DropdownItem
                        className="px-0"
                        onClick={() => setSalesStatusOption("android-sub")}
                      >
                        android-sub
                      </DropdownItem>
                      <DropdownItem
                        className="px-0"
                        onClick={() => setSalesStatusOption("ios-sub")}
                      >
                        ios-sub
                      </DropdownItem>
                      <DropdownItem
                        className="px-0"
                        onClick={() => setSalesStatusOption("web-inapp")}
                      >
                        web-inapp
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>

                  <Button color="primary" onClick={handleReset}>
                    <span className="me-2">Reset</span>
                    <i className="fas fa-redo"></i>
                  </Button>

                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                    }}
                    className="ms-auto"
                  >
                    <FormGroup>
                      <Input
                        type="text"
                        placeholder="Search"
                        onChange={(e) => {
                          let target = e.target;
                          setTimeout(() => {
                            setSearchQuery(target.value);
                          }, 1000);
                        }}
                      />
                    </FormGroup>
                  </Form>
                </div>

                <SalesReportTable
                  loading={loading}
                  productLogData={productLogData}
                  order={order}
                  handleOrder={handleOrder}
                  history={history}
                />

                <TablePagination
                  dataPerPage={productLogPerPage}
                  totalData={totalProductLog}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default SalesReport;
