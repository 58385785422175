import { useState } from "react";
import { Button, Input, Modal, ModalBody, ModalFooter,ModalHeader } from "reactstrap";
import { saveNewAuthorityUsers, getObjIds } from "../../service/parse-service";

function AddAuthorityUsersModal({ modal, setModal }) {
  const [newTutor, setNewTutor] = useState("");
  const [items, setItems] = useState([]);

  const addTutor = () => {
    if (!newTutor) return;
    setItems([...items, newTutor]);
    setNewTutor("");
  };

  const deleteItem = (index) => {
    const newItems = items.filter((item, i) => i !== index);
    setItems(newItems);
  };

  if (modal)
    return (
      <div>
        <Modal isOpen={modal} centered toggle={() => setModal(false)}>
        <ModalHeader>Add</ModalHeader>
          <ModalBody>
            <Input
              placeholder="Enter email"
              type="email"
              value={newTutor}
              onChange={(e) => setNewTutor(e.target.value)}
            />
            <Button className="mt-2" color="primary" onClick={() => addTutor()}>
              Add
            </Button>

            <div className="mt-4">
              <ul>
                {items?.map((item, index) => {
                  return (
                    <div key={index} className="d-flex justify-content-between align-items-center my-3">
                      <li className="fs-4">
                        {item}
                      </li>{" "}
                      <Button
                        className="ms-2 fs-6"
                        onClick={() => deleteItem(index)}
                      >
                        X
                      </Button>
                    </div>
                  );
                })}
              </ul>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={async () =>
                saveNewAuthorityUsers(
                  await getObjIds(items.map((item) => item))
                ).catch((err) => setItems([]))
              }
            >
              Save
            </Button>
            <Button color="secondary" onClick={() => setModal(false)}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  else return null;
}

export default AddAuthorityUsersModal;
