import { useState, useEffect } from "react";
import { MDBDataTable } from "mdbreact";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { ToastContainer } from "react-toastify";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "../../assets/scss/datatables.scss";
import { getMissedCall } from "../../service/parse-service";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { Link } from "react-router-dom";
import MissedCallTable from "./components/MissedCallTable";
import TablePagination from "../../components/TablePagination";

const MissedCall = ({ history }) => {
  const [loading, setLoading] = useState(false);
  const [dropdownOpenMissed, setDropdownOpenMissed] = useState(false);
  const [missedCallData, setMissedCallData] = useState([]);
  const [totalMissedCall, setTotalMissedCall] = useState(0);
  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [missedCallPerPage] = useState(10);
  //

  const [order, setOrder] = useState({
    column: "",
    direction: "",
  });

  const handleOrder = (column) => {
    let direction = "asc";
    if (order.column === column && order.direction === "asc") {
      direction = "desc";
    }
    setOrder({ column, direction });
  };

  useEffect(() => {
    const fetchMissedCall = async () => {
      setLoading(true);
      const results = await getMissedCall(
        currentPage,
        missedCallPerPage,
        order
      );
      setMissedCallData(results.missedCalls);
      setTotalMissedCall(results.totalCount);
      setLoading(false);
    };
    fetchMissedCall();
  }, [currentPage, missedCallPerPage, order]);

  const toggleMissed = () => setDropdownOpenMissed((prevState) => !prevState);

  return (
    <>
      <ToastContainer />
      <div className="page-content">
        <Breadcrumbs title="Dashboard" breadcrumbItem="Missed Call" />
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <CardTitle>Missed Call </CardTitle>
                <Dropdown
                  isOpen={dropdownOpenMissed}
                  toggle={toggleMissed}
                  className="me-2 mb-2"
                >
                  <DropdownToggle color="primary">
                     Missed <i className="fas fa-caret-down"></i>
                  </DropdownToggle>
                  <DropdownMenu>
                    <Link to="/activity">
                      <DropdownItem>Completed Call</DropdownItem>
                    </Link>
                  </DropdownMenu>
                </Dropdown>

                <MissedCallTable
                  missedCallData={missedCallData}
                  loading={loading}
                  order={order}
                  handleOrder={handleOrder}
                  history={history}
                />

                <TablePagination
                  dataPerPage={missedCallPerPage}
                  totalData={totalMissedCall}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default MissedCall;
