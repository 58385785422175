import { useEffect, useState } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import Loading from "../../../components/Common/Loading";
import { getSingleSupport } from "../../../service/parse-service";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import ImageModal from "../../../components/Modal/ImageModal";


const TicketDetail = () => {
  const [loading, setLoading] = useState(false);
  const [ticket, setTicket] = useState(null);
  const [imageModal, setImageModal] = useState({
    isOpen: false,
    image: "",
  });

  const id =
    window.location.pathname.split("/")[
      window.location.pathname.split("/").length - 1
    ];

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const ticketData = await getSingleSupport(id);
        if (ticketData) setTicket(ticketData);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    })();
  }, [id]);

  return (
    <>
      <ToastContainer />
      <ImageModal imageModal={imageModal} setImageModal={setImageModal} />
      <div className="page-content">
        <Breadcrumbs
          title="Dashboard"
          previousTicket="Ticket"
          breadcrumbItem="Ticket Detail"
        />
        {loading && <Loading loading={loading} />}
        {!loading && (
          <>
            <Link to="/ticket">
              <button
                type="button"
                className="btn btn-md btn-primary waves-effect waves-light mb-3"
              >
                Back
              </button>
            </Link>
            <Row>
              <Col lg={5}>
                <Card>
                  <CardBody>
                    <Row className="mb-4 border-bottom">
                      <Col lg={4}>Profile Image :</Col>
                      <Col lg={8}>
                        <img
                          src={
                            ticket?.user?.profile_image?.url
                              ? ticket?.user?.profile_image?.url
                              : `https://ui-avatars.com/api/?name=${ticket?.user?.nickname}`
                          }
                          alt={ticket?.user?.nickname}
                          className="img-fluid rounded-circle"
                          onClick={() =>
                            setImageModal({
                              isOpen: true,
                              image: ticket?.user?.profile_image?.url,
                            })
                          }
                          style={{ width: "100px", height: "100px",cursor:"pointer" }}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-4 border-bottom">
                      <Col lg={4}>Username :</Col>
                      <Col lg={8}>{ticket?.user?.nickname || "-"}</Col>
                    </Row>
                    <Row className="mb-4 border-bottom">
                      <Col lg={4}>Email :</Col>
                      <Col lg={8}>{ticket?.user?.email || "-"}</Col>
                    </Row>
                    <Row className="mb-4 border-bottom">
                      <Col lg={4}>Ticket Date:</Col>
                      <Col lg={8}>
                        {new Date(ticket?.createdAt).toLocaleString() || "-"}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg={6}>
                <Card>
                  <CardBody>
                    <Row className="mb-4">
                      <Col style={{ height: "250px" }} lg={3}>
                        Ticket Content :
                      </Col>
                      <Col lg={8}>{ticket?.content || "-"}</Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </>
        )}
      </div>
    </>
  );
};

export default TicketDetail;
