import { Modal, ModalBody } from "reactstrap";

function ImageModal({ imageModal, setImageModal }) {
  return (
    <div>
      <Modal
        isOpen={imageModal.isOpen}
        toggle={() =>
          setImageModal({
            isOpen: !imageModal.isOpen,
            image: imageModal.image,
          })
        }
        centered
      >
        <ModalBody className="d-flex justify-content-center align-items-center" >
          <img
            src={imageModal.image}
            style={{
              height: "auto",
              width: 400,
            }}
            alt="user"
          />
        </ModalBody>
      </Modal>
    </div>
  );
}

export default ImageModal;
