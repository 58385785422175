import { useEffect, useState } from "react";
import {
    getUsersForStatistics,
    getPreRegisterRecord,
} from "../service/parse-service";
import Loading from "./Common/Loading";
import { Table } from 'reactstrap';
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRange } from "react-date-range";
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
} from "reactstrap";

export const DashboardInfoUsers = () => {
    const [userData, setUserData] = useState([]);
    const [registerCountData, setRegisterCountData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [users, setUsers] = useState({
        webUsers: 0,
        androidUsers: 0,
        iOSUsers: 0,
    });
    const [registerCount, setRegisterCount] = useState({
        webInstall: 0,
        androidInstall: 0,
        iOSInstall: 0,
    });
    const [state, setState] = useState([
        {
            startDate: null,
            endDate: null,
            key: "selection",
        },
    ]);

    const currentYear = new Date().getFullYear();

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const users = await getUsersForStatistics(state);
            if (users) setUserData(users);
            const platformData = await getPreRegisterRecord(state);
            if (platformData) setRegisterCountData(platformData);
            setLoading(false);
        };
        fetchData();

    }, [state]);

    useEffect(() => {
        if (userData) {
            const webUsers = userData?.filter((item) => item?.platform === "web")?.length
            const androidUsers = userData?.filter((item) => item?.platform === "android")?.length
            const iOSUsers = userData?.filter((item) => item?.platform === "iOS")?.length
            setUsers({
                webUsers,
                androidUsers,
                iOSUsers,
                total: webUsers + androidUsers + iOSUsers,
            })
        }
    }, [userData])

    useEffect(() => {
        if (registerCountData) {
            const webInstall = registerCountData?.filter((item) => item?.platform === "web")?.length
            const androidInstall = registerCountData?.filter((item) => item?.platform === "android")?.length
            const iOSInstall = registerCountData?.filter((item) => item?.platform === "iOS")?.length

            setRegisterCount({
                webInstall,
                androidInstall,
                iOSInstall,
                total: webInstall + androidInstall + iOSInstall,
            })
        }
    }, [registerCountData])


    const toggle = () => setDropdownOpen((prevState) => !prevState);

    const resetDateFilter = () => {
        setState([
            {
                startDate: null,
                endDate: null,
                key: "selection",
            },
        ]);
    };
    return (
        <>
            <div className="d-flex justify-content-start mb-2">
                <Dropdown
                    isOpen={dropdownOpen}
                    toggle={toggle}
                    className="me-2"
                >
                    <DropdownToggle color="primary">
                        {state[0].startDate === null
                            ? "Date"
                            : `${state[0].startDate.toLocaleDateString()} - ${state[0].endDate.toLocaleDateString()}`}
                        <i className="fas fa-caret-down mx-1"></i>
                    </DropdownToggle>
                    <DropdownMenu>
                        <DateRange
                            editableDateInputs={true}
                            onChange={(item) => setState([item.selection])}
                            moveRangeOnFirstSelection={false}
                            ranges={state}
                            minDate={new Date(`01-01-${currentYear}`)}
                            maxDate={new Date(`12-31-${currentYear}`)}
                        />
                    </DropdownMenu>
                </Dropdown>
                <button
                    className="border border-white rounded btn-primary"
                    onClick={resetDateFilter}
                >
                    <span className="me-2">Reset</span>
                    <i className="fas fa-redo"></i>
                </button>
            </div>

            {loading && <div
                style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                }}
            >
                <Loading />
            </div>}
            {!loading && <Table>
                <thead>
                    <tr>
                        <th></th>
                        <th className="fw-bold">Web</th>
                        <th className="fw-bold">Android</th>
                        <th className="fw-bold">IOS</th>
                        <th className="fw-bold">Total</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th scope="row">Register</th>
                        <td>{users?.webUsers}</td>
                        <td>{users?.androidUsers}</td>
                        <td>{users?.iOSUsers}</td>
                        <td>{users?.total}</td>
                    </tr>
                    <tr>
                        <th scope="row">Install</th>
                        <td>{registerCount?.webInstall}</td>
                        <td>{registerCount?.androidInstall}</td>
                        <td>{registerCount?.iOSInstall}</td>
                        <td>{registerCount?.total}</td>
                    </tr>
                    <tr>
                        <th scope="row">Total</th>
                        <td>{users?.webUsers + registerCount?.webInstall}</td>
                        <td>{users?.androidUsers + registerCount?.androidInstall}</td>
                        <td>{users?.iOSUsers + registerCount?.iOSInstall}</td>
                        <td>{users?.total + registerCount?.total}</td>

                    </tr>
                </tbody>
            </Table>}
        </>)
};
