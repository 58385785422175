import { useState, useEffect } from "react";
import { MDBDataTable } from "mdbreact";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";
import { ToastContainer } from "react-toastify";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "../../assets/scss/datatables.scss";
import { getPaypalPayment } from "../../service/parse-service";
import Loading from "../../components/Common/Loading";
import { Calendar } from "react-date-range";
import { CSVLink } from "react-csv";

const Payments = () => {
  const [loading, setLoading] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [data, setData] = useState({
    columns: [
      {
        label: "ID",
        field: "batchId",
        sort: "asc",
        width: 150,
      },
      {
        label: "Username",
        field: "username",
        sort: "asc",
        width: 270,
      },
      {
        label: "Payment Date",
        field: "paymentDate",
        sort: "asc",
        width: 100,
      },
      {
        label: "Total Earnings",
        field: "totalEarnings",
        sort: "asc",
        width: 100,
      },
      {
        label: "Currency",
        field: "currency",
        sort: "asc",
        width: 100,
      },
      {
        label: "Payment Status",
        field: "paymentStatus",
        sort: "asc",
        width: 100,
      },
    ],
  });

  const currentYear = new Date().getFullYear();

  useEffect(() => {
    const fetchPayments = async () => {
      setLoading(true);
      let mappedResult = [];
      let csvData = [];
      const results = await getPaypalPayment();
      mappedResult = results.map((result) => ({
        batchId: result?.batch_id,
        username: result?.receiver,
        paymentDate: new Date(result?.createdAt).toLocaleString(),
        totalEarnings: result?.amount,
        currency: result?.currency,
        paymentStatus: result?.status,
      }));
      csvData = results.map((result) => ({
        batchId: result?.batch_id,
        username: result?.receiver,
        paymentDate: new Date(result?.createdAt).toLocaleString(),
        totalEarnings: result?.amount,
        currency: result?.currency,
        paymentStatus: result?.status,
      }));
      setCsvData(csvData);
      setData((prevState) => ({
        ...prevState,
        rows: mappedResult,
      }));
      setLoading(false);
    };
    fetchPayments();
  }, []);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const handleDateSelect = () => {};

  const resetDateFilter = () => {};

  return (
    <>
      <ToastContainer />
      <div className="page-content">
        <Breadcrumbs title="Dashboard" breadcrumbItem="Payment" />

        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <CardTitle>Payment</CardTitle>
                <div className="d-flex justify-content-start mb-2">
                  <Dropdown
                    isOpen={dropdownOpen}
                    toggle={toggle}
                    className="me-2"
                  >
                    <DropdownToggle color="primary">
                      Date <i className="fas fa-caret-down"></i>
                    </DropdownToggle>
                    <DropdownMenu>
                      <Calendar
                        minDate={new Date(`01-01-${currentYear}`)}
                        maxDate={new Date(`12-31-${currentYear}`)}
                        date={new Date()}
                        onChange={handleDateSelect}
                      />
                    </DropdownMenu>
                  </Dropdown>
                  <button
                    className="border border-white rounded btn-primary"
                    onClick={resetDateFilter}
                  >
                    <span className="me-2">Reset</span>
                    <i className="fas fa-redo"></i>
                  </button>
                </div>
                <div className="d-flex justify-content-end mb-2">
                  <CSVLink data={csvData} filename={"users.csv"}>
                    <button
                      type="button"
                      className="btn btn-primary waves-effect waves-light"
                    >
                      <i className="bx bx-download font-size-16 align-middle me-2"></i>{" "}
                      Download
                    </button>
                  </CSVLink>
                </div>
                {loading && <Loading loading={loading} />}
                {!loading && (
                  <MDBDataTable
                    exportToCSV
                    hover
                    responsive
                    striped
                    bordered
                    data={data}
                  />
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Payments;
