import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Row, Col, BreadcrumbItem } from "reactstrap";

const Breadcrumb = (props) => {
  return (
    <Row>
      <Col className="col-12">
        <div className="page-title-box d-flex align-items-start align-items-center justify-content-between">
          <h4 className="page-title mb-0 font-size-18">
            {props.breadcrumbItem}
          </h4>

          <div className="page-title-right">
            <ol className="breadcrumb m-0">
              <BreadcrumbItem>
                <Link to="/">{props.title}</Link>
              </BreadcrumbItem>
              {props.previousTutorPending && (
                <BreadcrumbItem>
                  <Link to="/pending-tutors">{props.previousTutorPending}</Link>
                </BreadcrumbItem>
              )}

              {props.previousTicket && (
                <BreadcrumbItem>
                  <Link to="/ticket">{props.previousTicket}</Link>
                </BreadcrumbItem>
              )}

              {props.previousTutors && (
                <BreadcrumbItem>
                  <Link to="/tutors">{props.previousTutors}</Link>
                </BreadcrumbItem>
              )}

              {props.previousReport && (
                <BreadcrumbItem>
                  <Link to="/report">{props.previousReport}</Link>
                </BreadcrumbItem>
              )}

              {props.previousTutorEarning && (
                <BreadcrumbItem>
                  <Link to="/tutor-earning">{props.previousTutorEarning}</Link>
                </BreadcrumbItem>
              )}

              {props.previousUsers && (
                <BreadcrumbItem>
                  <Link to="/users">{props.previousUsers}</Link>
                </BreadcrumbItem>
              )}
              <BreadcrumbItem active>
                <Link to="#">{props.breadcrumbItem}</Link>
              </BreadcrumbItem>
            </ol>
          </div>
        </div>
      </Col>
    </Row>
  );
};

Breadcrumb.propTypes = {
  breadcrumbItem: PropTypes.string,
  title: PropTypes.string,
};

export default Breadcrumb;
