import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import Swal from "sweetalert2";
import { deleteUser, userBan, userUnBan } from "../../../service/parse-service";
import { Link } from "react-router-dom";
import Loading from "../../../components/Common/Loading";

const UsersTable = ({
  usersData,
  loading,
  totalBalance,
  totalBoughtCredit,
  totalSpendCredit,
  handleOrder,
  order,
  setImageModal,
}) => {
  const handleDeleteUser = (userId) => {
    Swal.fire({
      title: "Are you sure you want to delete this user?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          Swal.fire("User deleted.");
          await deleteUser(userId);
          window.location.reload();
        } catch (e) {
          Swal.fire(e.message);
        }
      }
    });
  };

  const handleBan = (userId) => {
    Swal.fire({
      title: "Are you sure you want to ban this user?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ban",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          Swal.fire("User Banned.");
          await userBan(userId);
          window.location.reload();
        } catch (e) {
          Swal.fire(e.message);
        }
      }
    });
  };

  const handleUnban = (userId) => {
    Swal.fire({
      title: "Are you sure you want to unban this user?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Unban",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          Swal.fire("User Unbanned.");
          await userUnBan(userId);
          window.location.reload();
        } catch (e) {
          Swal.fire(e.message);
        }
      }
    });
  };

  return (
    <>
      <div className="table-rep-plugin">
        <div
          className="table-responsive mb-0 "
          data-pattern="priority-columns"
          style={{
            position: "relative",
            height: "100vh",
          }}
        >
          <Table className="table table-striped table-bordered">
            <Thead>
              <Tr>
                <Th>Image</Th>
                <Th>Username</Th>
                <Th
                  onClick={() => handleOrder("createdAt")}
                  style={{ cursor: "pointer" }}
                >
                  Created{" "}
                  <div className="d-inline-block">
                    <i
                      className="dripicons-arrow-thin-up"
                      style={{
                        opacity:
                          order.column === "createdAt" &&
                          order.direction === "desc"
                            ? 1
                            : 0.6,
                      }}
                    ></i>
                    <i
                      className="dripicons-arrow-thin-down"
                      style={{
                        opacity:
                          order.column === "createdAt" &&
                          order.direction === "asc"
                            ? 1
                            : 0.6,
                      }}
                    ></i>
                  </div>
                </Th>
                <Th>Gender</Th>
                <Th>Email</Th>
                <Th>Subs</Th>
                <Th
                  onClick={() => handleOrder("bought_credit")}
                  style={{ cursor: "pointer" }}
                >
                  Purchased Credits{" "}
                  <div className="d-inline-block">
                    <i
                      className="dripicons-arrow-thin-up"
                      style={{
                        opacity:
                          order.column === "bought_credit" &&
                          order.direction === "desc"
                            ? 1
                            : 0.6,
                      }}
                    ></i>
                    <i
                      className="dripicons-arrow-thin-down"
                      style={{
                        opacity:
                          order.column === "bought_credit" &&
                          order.direction === "asc"
                            ? 1
                            : 0.6,
                      }}
                    ></i>
                  </div>
                </Th>
                <Th
                  onClick={() => handleOrder("spend_credit")}
                  style={{ cursor: "pointer" }}
                >
                  Spent Credits{" "}
                  <div className="d-inline-block">
                    <i
                      className="dripicons-arrow-thin-up"
                      style={{
                        opacity:
                          order.column === "spend_credit" &&
                          order.direction === "desc"
                            ? 1
                            : 0.6,
                      }}
                    ></i>
                    <i
                      className="dripicons-arrow-thin-down"
                      style={{
                        opacity:
                          order.column === "spend_credit" &&
                          order.direction === "asc"
                            ? 1
                            : 0.6,
                      }}
                    ></i>
                  </div>
                </Th>
                <Th
                  onClick={() => handleOrder("balance")}
                  style={{ cursor: "pointer" }}
                >
                  Active Credits{" "}
                  <div className="d-inline-block">
                    <i
                      className="dripicons-arrow-thin-up"
                      style={{
                        opacity:
                          order.column === "balance" &&
                          order.direction === "desc"
                            ? 1
                            : 0.6,
                      }}
                    ></i>
                    <i
                      className="dripicons-arrow-thin-down"
                      style={{
                        opacity:
                          order.column === "balance" &&
                          order.direction === "asc"
                            ? 1
                            : 0.6,
                      }}
                    ></i>
                  </div>
                </Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            {loading ? (
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: 500,
                  height: 500,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Loading />
              </div>
            ) : (
              <Tbody>
                {usersData.totalCount !== 0 && (
                  <Tr>
                    <Th></Th>
                    <Th></Th>
                    <Th></Th>
                    <Th></Th>
                    <Th></Th>
                    <Th></Th>
                    <Th className="fw-bold">{totalBoughtCredit} Coins</Th>
                    <Th className="fw-bold">{totalSpendCredit} Coins</Th>
                    <Th className="fw-bold">{totalBalance} Coins</Th>
                    <Th></Th>
                  </Tr>
                )}
                {usersData?.users?.map((user, index) => (
                  <Tr key={index}>
                    <Th>
                      <div className="d-flex justify-content-center align-items-center">
                        <img
                          src={user.profile_image?.url}
                          alt={user.nickname}
                          onClick={() =>
                            setImageModal({
                              isOpen: true,
                              image: user.profile_image?.url,
                            })
                          }
                          style={{
                            width: "60x",
                            height: "60px",
                            cursor: "pointer",
                          }}
                          className="img-thumbnail"
                        />
                      </div>
                    </Th>
                    <Td>{user.nickname}</Td>
                    <Td>{new Date(user.createdAt).toLocaleString()}</Td>
                    <Td>
                      {user.gender?.objectId === "a2bZa53s3l" ? "M" : "F"}
                    </Td>
                    <Td>{user.email}</Td>
                    <Td>{user.premiumPlan === true ? "Ac" : "Inac"}</Td>
                    <Td>
                      {`${
                        isNaN(user.bought_credit)
                          ? "-"
                          : `${user.bought_credit} Coins`
                      }`}
                    </Td>
                    <Td>
                      {isNaN(user.spend_credit)
                        ? "-"
                        : `${user.spend_credit} Coins`}
                    </Td>
                    <Td>
                      {isNaN(user.balance) ? "-" : `${user.balance} Coins`}
                    </Td>

                    <Td>
                      <div className="d-flex ">
                        <Link to={`/users/${user.objectId}`}>
                          <button
                            type="button"
                            title="Edit User"
                            className="btn btn-md btn-primary waves-effect waves-light d-flex justify-content-center align-items-center"
                          >
                            <i className="bx bxs-edit align-middle"></i>
                          </button>
                        </Link>

                        <Link to={`/users/history/${user.objectId}`}>
                          <button
                            type="button"
                            title="History User"
                            className="btn btn-md btn-primary waves-effect waves-light d-flex justify-content-center align-items-center ms-2"
                          >
                            <i className="fas fa-history align-middle"></i>
                          </button>
                        </Link>

                        <button
                          onClick={() => handleDeleteUser(user?.objectId)}
                          type="button"
                          title="Delete User"
                          className="btn btn-md btn-danger waves-effect waves-light d-flex justify-content-center align-items-center ms-2"
                        >
                          <i className="fas fa-solid fa-trash"></i>
                        </button>

                        <button
                          onClick={() => {
                            user?.isBanned
                              ? handleUnban(user?.objectId)
                              : handleBan(user?.objectId);
                          }}
                          type="button"
                          title={user?.isBanned ? "Unban User" : "Ban User"}
                          className="btn btn-md btn-danger waves-effect waves-light d-flex justify-content-center align-items-center ms-2"
                        >
                          {user?.isBanned ? (
                            <i className="fas fa-solid fa-lock-open"></i>
                          ) : (
                            <i className="fas fa-solid fa-lock"></i>
                          )}
                        </button>
                      </div>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            )}
          </Table>
        </div>
      </div>
    </>
  );
};

export default UsersTable;
