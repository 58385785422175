import { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Form,
  FormGroup,
  Input,
  Button,
} from "reactstrap";
import { ToastContainer } from "react-toastify";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "../../assets/scss/datatables.scss";
import { getTutorEarningBySpecificDate } from "../../service/parse-service";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { Calendar } from "react-date-range";
import { Link } from "react-router-dom";
import ActivityTable from "./components/ActivityTable";
import TablePagination from "../../components/TablePagination";

const Activity = ({ history }) => {
  const [loading, setLoading] = useState(false);
  const [startTime, setStartTime] = useState(new Date("2023-01-01"));
  const [endTime, setEndTime] = useState(new Date());
  const [dropdownOpenTime, setDropdownOpenTime] = useState(false);
  const [dropdownOpenMissed, setDropdownOpenMissed] = useState(false);
  const [activityData, setActivityData] = useState([]);
  const [totalActivities, setTotalActivities] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [activitiesPerPage] = useState(10);
  const [order, setOrder] = useState({
    column: "",
    direction: "",
  });

  const handleOrder = (column) => {
    let direction = "asc";
    if (order.column === column && order.direction === "asc") {
      direction = "desc";
    }
    setOrder({ column, direction });
  };

  const currentYear = new Date().getFullYear();

  useEffect(() => {
    const fetchCall = async () => {
      setLoading(true);
      const results = await getTutorEarningBySpecificDate(
        startTime,
        endTime,
        searchQuery,
        currentPage,
        activitiesPerPage,
        order
      );

      setActivityData(results.activities);
      setTotalActivities(results.totalCount);
      setLoading(false);
    };
    fetchCall();
  }, [startTime, endTime, searchQuery, currentPage, activitiesPerPage, order]);

  const toggleTime = () => setDropdownOpenTime((prevState) => !prevState);
  const toggleMissed = () => setDropdownOpenMissed((prevState) => !prevState);

  const handleTimeSelect = (type) => {
    const today = new Date();
    let result = "";
    if (type === "oneHour") {
      result = new Date(today.getTime() - 60 * 60 * 1000);
    } else if (type === "sixHour") {
      result = new Date(today.getTime() - 60 * 60 * 1000 * 6);
    } else if (type === "twelveHour") {
      result = new Date(today.getTime() - 60 * 60 * 1000 * 12);
    } else if (type === "oneDay") {
      result = new Date(today.getTime() - 60 * 60 * 1000 * 24);
    }
    setStartTime(result);
  };

  const handleDateSelect = (value) => {
    setStartTime(value);
    setEndTime(new Date(value.getTime() + 60 * 60 * 1000 * 24));
  };

  const resetDateFilter = () => {
    setStartTime(new Date("2020-01-01"));
    setEndTime(new Date());
    setCurrentPage(1);
    setOrder({ column: "", direction: "" });
  };

  return (
    <>
      <ToastContainer />
      <div className="page-content">
        <Breadcrumbs title="Dashboard" breadcrumbItem="Activity" />
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <CardTitle className="mb-3">Activity</CardTitle>
                <div className="d-flex justify-content-start mb-3">
                  <Dropdown
                    isOpen={dropdownOpenTime}
                    toggle={toggleTime}
                    className="me-2"
                  >
                    <DropdownToggle color="primary">
                      {`${startTime.toLocaleDateString()} - ${endTime.toLocaleDateString()}`}{" "}
                      <i className="fas fa-caret-down"></i>
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem onClick={() => handleTimeSelect("oneHour")}>
                        Last 1 hours
                      </DropdownItem>
                      <DropdownItem onClick={() => handleTimeSelect("sixHour")}>
                        Last 6 hours
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => handleTimeSelect("twelveHour")}
                      >
                        Last 12 hours
                      </DropdownItem>
                      <DropdownItem onClick={() => handleTimeSelect("oneDay")}>
                        Last 24 hours
                      </DropdownItem>
                      <Calendar
                        minDate={new Date(`01-01-${currentYear}`)}
                        maxDate={new Date(`12-31-${currentYear}`)}
                        date={new Date()}
                        onChange={handleDateSelect}
                      />
                    </DropdownMenu>
                  </Dropdown>
                  <Dropdown
                    isOpen={dropdownOpenMissed}
                    toggle={toggleMissed}
                    className="me-2"
                  >
                    <DropdownToggle color="primary">
                      Completed <i className="fas fa-caret-down"></i>
                    </DropdownToggle>
                    <DropdownMenu>
                      <Link to="/missed-call">
                        <DropdownItem>Missed call</DropdownItem>
                      </Link>
                    </DropdownMenu>
                  </Dropdown>
                  <Button color="primary" onClick={resetDateFilter}>
                    <span className="me-2">Reset</span>
                    <i className="fas fa-redo"></i>
                  </Button>

                  <Form
                    className="ms-auto"
                    onSubmit={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <FormGroup>
                      <Input
                        type="text"
                        placeholder="Search"
                        onChange={(e) => {
                          let target = e.target;
                          setTimeout(() => {
                            setSearchQuery(target.value);
                          }, 1000);
                        }}
                      />
                    </FormGroup>
                  </Form>
                </div>

                <ActivityTable
                  activityData={activityData}
                  loading={loading}
                  order={order}
                  handleOrder={handleOrder}
                  history={history}
                />

                <TablePagination
                  dataPerPage={activitiesPerPage}
                  totalData={totalActivities}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Activity;
