import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
  changeLayout,
  changeTopbarTheme,
  changeLayoutWidth,
} from "../../store/actions";
// Other Layout related Component

import Header from "./Header";
import Footer from "./Footer";

const Layout = ({ props }) => {
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  /**
   * Opens the menu - mobile
   */
  const openMenu = () => {
    setIsMenuOpened({ isMenuOpened: !isMenuOpened });
  };
  useEffect(() => {
    if (props.isPreloader === true) {
      document.getElementById("preloader").style.display = "block";
      document.getElementById("status").style.display = "block";

      setTimeout(function () {
        document.getElementById("preloader").style.display = "none";
        document.getElementById("status").style.display = "none";
      }, 2500);
    } else {
      document.getElementById("preloader").style.display = "none";
      document.getElementById("status").style.display = "none";
    }

    // Scrollto 0,0
    window.scrollTo(0, 0);

    const title = props.location.pathname;
    let currentage = title.charAt(1).toUpperCase() + title.slice(2);

    document.title = currentage + " | Admin Dashboard";

    props.changeLayout("horizontal");
    if (props.topbarTheme) {
      props.changeTopbarTheme(props.topbarTheme);
    }
    if (props.layoutWidth) {
      props.changeLayoutWidth(props.layoutWidth);
    }
  }, []);
  return (
    <>
      <div id="preloader">
        <div id="status">
          <div className="spinner-chase">
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div id="layout-wrapper">
          <header id="page-topbar">
            <Header
              theme={props.topbarTheme}
              isMenuOpened={isMenuOpened}
              openLeftMenuCallBack={openMenu}
            ></Header>
          </header>
          <div className="main-content">
            {props.children}
            <Footer />
          </div>
        </div>
      </div>
      {/* {props.showRightSidebar ? <Rightbar /> : null} */}
    </>
  );
};
Layout.propTypes = {
  changeLayout: PropTypes.func,
  changeLayoutWidth: PropTypes.func,
  changeTopbarTheme: PropTypes.func,
  children: PropTypes.object,
  isPreloader: PropTypes.any,
  layoutWidth: PropTypes.any,
  location: PropTypes.object,
  showRightSidebar: PropTypes.any,
  topbarTheme: PropTypes.any,
};

const mapStatetoProps = (state) => {
  return {
    ...state.Layout,
  };
};
export default connect(mapStatetoProps, {
  changeTopbarTheme,
  changeLayout,
  changeLayoutWidth,
})(Layout);
