import { useEffect, useState } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import Loading from "../../../components/Common/Loading";
import { getTutorEarning } from "../../../service/parse-service";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { MDBDataTable } from "mdbreact";
import { CSVLink } from "react-csv";

const TutorsDetails = () => {
  const [loading, setLoading] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [data, setData] = useState({
    columns: [
      {
        label: "Tutor Name",
        field: "tutorname",
        sort: "asc",
        width: 150,
      },
      {
        label: "Conversation Date",
        field: "date",
        sort: "asc",
        width: 150,
      },
      {
        label: "Interviewer",
        field: "interviewer",
        sort: "asc",
        width: 270,
      },
      {
        label: "Total Seconds",
        field: "totalSeconds",
        sort: "asc",
        width: 100,
      },
      {
        label: "Total Minutes",
        field: "totalMinutes",
        sort: "asc",
        width: 100,
      },
      {
        label: "Spent Credits",
        field: "spentCredits",
        sort: "disabled",
        width: 100,
      },
      {
        label: "Tutor Earnings",
        field: "tutorEarn",
        sort: "disabled",
        width: 100,
      },
      {
        label: "Reason",
        field: "reason",
        sort: "asc",
        width: 100,
      },
    ],
  });

  const id =
    window.location.pathname.split("/")[
      window.location.pathname.split("/").length - 1
    ];

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        let mappedResult = [];
        let csvData = [];
        const tutorData = await getTutorEarning(id);
        mappedResult = tutorData.map((tutor) => {
          return {
            tutorname: tutor?.fullname,
            date: new Date(tutor?.startTime?.iso).toLocaleString(),
            interviewer: tutor?.user?.nickname,
            totalSeconds: tutor?.total_seconds,
            totalMinutes: tutor?.total_minutes,
            spentCredits: isNaN(tutor?.total_minutes)
              ? "-"
              : `${tutor?.total_minutes * 60} Coins`,
            tutorEarn: isNaN(tutor?.amount) ? "-" : `$ ${tutor?.amount}`,
            reason: tutor?.isCall === true ? "Session" : "Tip",
          };
        });
        csvData = tutorData.map((tutor) => {
          return {
            tutorname: tutor?.fullname,
            date: new Date(tutor?.startTime?.iso).toLocaleString(),
            interviewer: tutor?.user?.nickname,
            totalSeconds: tutor?.total_seconds,
            totalMinutes: tutor?.total_minutes,
            spentCredits: isNaN(tutor?.total_minutes)
              ? "-"
              : `${tutor?.total_minutes * 60} Coins`,
            tutorEarn: isNaN(tutor?.amount) ? "-" : `$ ${tutor?.amount}`,
            reason: tutor?.isCall === true ? "Session" : "Tip",
          };
        });
        mappedResult = mappedResult.filter((item) => item);
        csvData = mappedResult.filter((item) => item);
        setCsvData(csvData);
        setData((prevState) => ({
          ...prevState,
          rows: mappedResult,
        }));
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    })();
  }, [id]);

  return (
    <>
      <ToastContainer />
      <div className="page-content">
        <Breadcrumbs
          title="Dashboard"
          previousTutors="Tutors"
          breadcrumbItem="Tutor Conversations Detail"
        />
        <Link to="/tutors">
          <button
            type="button"
            className="btn btn-md btn-primary waves-effect waves-light mb-3"
          >
            Back
          </button>
        </Link>
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <div className="d-flex justify-content-end mb-2">
                  <CSVLink data={csvData} filename={"users.csv"}>
                    <button
                      type="button"
                      className="btn btn-primary waves-effect waves-light"
                    >
                      <i className="bx bx-download font-size-16 align-middle me-2"></i>{" "}
                      Download
                    </button>
                  </CSVLink>
                </div>
                {loading && <Loading loading={loading} />}
                {!loading && (
                  <MDBDataTable
                    exportToCSV
                    hover
                    responsive
                    striped
                    bordered
                    data={data}
                  />
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default TutorsDetails;
