import { useEffect, useState } from "react";
import {
    getProductLogForStatistics,
} from "../service/parse-service";
import Loading from "./Common/Loading";
import { Table } from 'reactstrap';
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRange } from "react-date-range";
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
} from "reactstrap";

export const DashboardInfoSubs = () => {
    const [purchaseData, setPurchaseData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [purchaseAndSubs, setPurchaseAndSubs] = useState({
        webForPurchaseCount: 0,
        androidForPurchaseCount: 0,
        iOSForPurchaseCount: 0,
        totalPurchaseCount: 0,

        webForSubsCount: 0,
        androidForSubsCount: 0,
        iOSForSubsCount: 0,
        totalSubsCount: 0,

        webForPurchaseAmount: 0,
        androidForPurchaseAmount: 0,
        iOSForPurchaseAmount: 0,
        totalPurchaseAmount: 0,

        webForSubsAmount: 0,
        androidForSubsAmount: 0,
        iOSForSubsAmount: 0,
        totalSubsAmount: 0,

        totalWebCount: 0,
        totalAndroidCount: 0,
        totaliOSCount: 0,
        totalWebAmount: 0,
        totalAndroidAmount: 0,
        totaliOSAmount: 0,
        totalAllCount: 0,
        totalAllAmount: 0,

    });
    const [state, setState] = useState([
        {
            startDate: null,
            endDate: null,
            key: "selection",
        },
    ]);

    const currentYear = new Date().getFullYear();

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const results = await getProductLogForStatistics(state);
            if (results) setPurchaseData(results);
            setLoading(false);
        };
        fetchData();
    }, [state]);

    useEffect(() => {
        if (purchaseData) {
            const webForPurchase = purchaseData?.filter((item) => item?.productType.includes("web-inapp"))
            const androidForPurchase = purchaseData?.filter((item) => item?.productType.includes("android-inapp"))
            const iOSForPurchase = purchaseData?.filter((item) => item?.productType.includes("ios-inapp"))

            const webForSubs = purchaseData?.filter((item) => item?.productType.includes("web-sub"))
            const androidForSubs = purchaseData?.filter((item) => item?.productType.includes("android-sub"))
            const iOSForSubs = purchaseData?.filter((item) => item?.productType.includes("ios-sub"))

            const webForPurchaseCount = webForPurchase?.length;
            const androidForPurchaseCount = androidForPurchase?.length;
            const iOSForPurchaseCount = iOSForPurchase?.length;
            const totalPurchaseCount = webForPurchaseCount + androidForPurchaseCount + iOSForPurchaseCount;

            const webForPurchaseAmount = webForPurchase?.reduce((a, item) => a + (item?.price || 0), 0)
            const androidForPurchaseAmount = androidForPurchase?.reduce((a, item) => a + (item?.price || 0), 0)
            const iOSForPurchaseAmount = iOSForPurchase?.reduce((a, item) => a + (item?.price || 0), 0);
            const totalPurchaseAmount = webForPurchaseAmount + androidForPurchaseAmount + iOSForPurchaseAmount;

            const webForSubsCount = webForSubs?.length;
            const androidForSubsCount = androidForSubs?.length;
            const iOSForSubsCount = iOSForSubs?.length;
            const totalSubsCount = webForSubsCount + androidForSubsCount + iOSForSubsCount

            const webForSubsAmount = webForSubs?.reduce((a, item) => a + (item?.price || 0), 0)
            const androidForSubsAmount = androidForSubs?.reduce((a, item) => a + (item?.price || 0), 0)
            const iOSForSubsAmount = iOSForSubs?.reduce((a, item) => a + (item?.price || 0), 0)
            const totalSubsAmount = webForSubsAmount + androidForSubsAmount + iOSForSubsAmount;

            setPurchaseAndSubs({
                webForPurchaseCount,
                androidForPurchaseCount,
                iOSForPurchaseCount,
                totalPurchaseCount,

                webForPurchaseAmount: webForPurchaseAmount.toFixed(2),
                androidForPurchaseAmount: androidForPurchaseAmount.toFixed(2),
                iOSForPurchaseAmount: iOSForPurchaseAmount.toFixed(2),
                totalPurchaseAmount: totalPurchaseAmount.toFixed(2),

                webForSubsCount,
                androidForSubsCount,
                iOSForSubsCount,
                totalSubsCount,

                webForSubsAmount: webForSubsAmount.toFixed(2),
                androidForSubsAmount: androidForSubsAmount.toFixed(2),
                iOSForSubsAmount: iOSForSubsAmount.toFixed(2),
                totalSubsAmount: totalSubsAmount.toFixed(2),

                totalWebCount: webForSubsCount + webForPurchaseCount,
                totalAndroidCount: androidForSubsCount + androidForPurchaseCount,
                totaliOSCount: iOSForSubsCount + iOSForPurchaseCount,
                totalWebAmount: (webForSubsAmount + webForPurchaseAmount).toFixed(2),
                totalAndroidAmount: (androidForSubsAmount + androidForPurchaseAmount).toFixed(2),
                totaliOSAmount: (iOSForSubsAmount + iOSForPurchaseAmount).toFixed(2),
                totalAllCount: webForSubsCount + webForPurchaseCount + androidForSubsCount + androidForPurchaseCount + iOSForSubsCount + iOSForPurchaseCount,
                totalAllAmount: (webForSubsAmount + webForPurchaseAmount + androidForSubsAmount + androidForPurchaseAmount + iOSForSubsAmount + iOSForPurchaseAmount).toFixed(2),
            })
        }
    }, [purchaseData])

    const toggle = () => setDropdownOpen((prevState) => !prevState);

    const resetDateFilter = () => {
        setState([
            {
                startDate: null,
                endDate: null,
                key: "selection",
            },
        ]);
    };

    return (
        <>
            <div className="d-flex justify-content-start mb-2">
                <Dropdown
                    isOpen={dropdownOpen}
                    toggle={toggle}
                    className="me-2"
                >
                    <DropdownToggle color="primary">
                        {state[0].startDate === null
                            ? "Date"
                            : `${state[0].startDate.toLocaleDateString()} - ${state[0].endDate.toLocaleDateString()}`}
                        <i className="fas fa-caret-down mx-1"></i>
                    </DropdownToggle>
                    <DropdownMenu>
                        <DateRange
                            editableDateInputs={true}
                            onChange={(item) => setState([item.selection])}
                            moveRangeOnFirstSelection={false}
                            ranges={state}
                            minDate={new Date(`01-01-${currentYear}`)}
                            maxDate={new Date(`12-31-${currentYear}`)}
                        />
                    </DropdownMenu>
                </Dropdown>
                <button
                    className="border border-white rounded btn-primary"
                    onClick={resetDateFilter}
                >
                    <span className="me-2">Reset</span>
                    <i className="fas fa-redo"></i>
                </button>
            </div>

            {loading && <div
                style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                }}
            >
                <Loading />
            </div>}

            {!loading && <Table>
                <thead>
                    <tr>
                        <th></th>
                        <th className="fw-bold">Web</th>
                        <th className="fw-bold">Android</th>
                        <th className="fw-bold">IOS</th>
                        <th className="fw-bold">Total</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th scope="row">Purchase</th>
                        <td>{`${purchaseAndSubs?.webForPurchaseCount} (${purchaseAndSubs?.webForPurchaseAmount})`}</td>
                        <td>{`${purchaseAndSubs?.androidForPurchaseCount} (${purchaseAndSubs?.androidForPurchaseAmount})`}</td>
                        <td>{`${purchaseAndSubs?.iOSForPurchaseCount} (${purchaseAndSubs?.iOSForPurchaseAmount})`}</td>
                        <td>{`${purchaseAndSubs?.totalPurchaseCount} (${purchaseAndSubs?.totalPurchaseAmount})`}</td>
                    </tr>
                    <tr>
                        <th scope="row">Subscription</th>
                        <td>{`${purchaseAndSubs?.webForSubsCount} (${purchaseAndSubs?.webForSubsAmount})`}</td>
                        <td>{`${purchaseAndSubs?.androidForSubsCount} (${purchaseAndSubs?.androidForSubsAmount})`}</td>
                        <td>{`${purchaseAndSubs?.iOSForSubsCount} (${purchaseAndSubs?.iOSForSubsAmount})`}</td>
                        <td>{`${purchaseAndSubs?.totalSubsCount} (${purchaseAndSubs?.totalSubsAmount})`}</td>
                    </tr>
                    <tr>
                        <th scope="row">Total</th>
                        <td>{`${purchaseAndSubs?.totalWebCount} (${purchaseAndSubs?.totalWebAmount})`}</td>
                        <td>{`${purchaseAndSubs?.totalAndroidCount} (${purchaseAndSubs?.totalAndroidAmount})`}</td>
                        <td>{`${purchaseAndSubs?.totaliOSCount} (${purchaseAndSubs?.totaliOSAmount})`}</td>
                        <td>{`${purchaseAndSubs?.totalAllCount} (${purchaseAndSubs?.totalAllAmount})`}</td>
                    </tr>
                </tbody>
            </Table>}
        </>
    );
};
