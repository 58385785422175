import { useState } from "react";
import { Tr, Td } from "react-super-responsive-table";
import { Link } from "react-router-dom";
import { changeUserAvailibility } from "../../../service/parse-service";

const TutorsTr = ({
  tutor,
  setImageModal,
  handleDeleteUser,
  currentUserData,
  history,
}) => {
  const [isHoverName, setIsHoverName] = useState(false);
  const [isTutorAvailable, setIsTutorAvailable] = useState(tutor?.available);

  const handleSwitch = () => {
    setIsTutorAvailable(!isTutorAvailable);
    changeUserAvailibility(tutor?.objectId, !isTutorAvailable);
  };

  const handleMouseEnter = () => {
    setIsHoverName(true);
  };

  const handleMouseLeave = () => {
    setIsHoverName(false);
  };

  const isHoverNameStyles = {
    cursor: isHoverName ? "pointer" : "default",
    textDecoration: isHoverName ? "underline" : "none",
    color: isHoverName ? "#007bff" : "",
  };

  return (
    <Tr>
      <Td>
        <div className="d-flex justify-content-center align-items-center">
          <img
            src={tutor.profile_image?.url}
            alt={tutor.nickname}
            onClick={() =>
              setImageModal({
                isOpen: true,
                image: tutor.profile_image?.url,
              })
            }
            style={{
              width: "60px",
              height: "60px",
              cursor: "pointer",
            }}
            className="img-thumbnail cursor-pointer"
          />
        </div>
      </Td>

      <Td
        style={isHoverNameStyles}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={() => history.push(`/users/${tutor?.objectId}`)}
      >
        {tutor.nickname}
      </Td>
      <Td>{tutor.gender?.objectId === "a2bZa53s3l" ? "M" : "F"}</Td>
      <Td>{tutor.email}</Td>
      <Td>{new Date(tutor.createdAt).toLocaleDateString()}</Td>
      <Td>{tutor.numberOfCallsAnswered}</Td>
      <Td>{tutor.numberOfCallsMissed}</Td>
      <Td>
        {`${
          isNaN(tutor?.callAnswerRate)
            ? "-"
            : (tutor?.callAnswerRate * 100).toPrecision(2) + "%"
        }`}
      </Td>
      <Td>
        {isNaN(tutor?.totalEarning)
          ? "-"
          : `$ ${tutor?.totalEarning.toFixed(2)}`}
      </Td>
      <Td>{isNaN(tutor?.earning) ? "-" : `$ ${tutor?.earning}`}</Td>
      <Td>
        <div className="form-check form-switch">
          <input
            className="form-check-input"
            type="checkbox"
            role="switch"
            checked={isTutorAvailable}
            onChange={handleSwitch}
            style={{
              height: "20px",
              width: "40px",
              cursor: "pointer",
            }}
          />
        </div>
      </Td>
      <Td>
        <div className="d-flex">
          <Link to={`/tutors-details/${tutor?.objectId}`}>
            <button
              type="button"
              title="Detail"
              className="btn btn-md btn-primary waves-effect waves-light d-flex justify-content-center align-items-center "
            >
              <i className="bx bxs-show"></i>
            </button>
          </Link>

          {currentUserData?.get("username") === "admin" && (
            <>
              <Link to={`/application-detail/${tutor?.objectId}`}>
                <button
                  type="button"
                  title="Application Detail"
                  className="btn btn-md btn-primary waves-effect waves-light d-flex justify-content-center align-items-center ms-2"
                >
                  <i className="bx bx-detail"></i>
                </button>
              </Link>

              <button
                onClick={() => handleDeleteUser(tutor?.objectId)}
                type="button"
                title="Delete Tutor"
                className="btn btn-md btn-danger waves-effect waves-light d-flex justify-content-center align-items-center ms-2"
              >
                <i className="fas fa-solid fa-trash"></i>
              </button>
            </>
          )}
        </div>
      </Td>
    </Tr>
  );
};

export default TutorsTr;
