import { useState } from "react";
import { Tr, Td } from "react-super-responsive-table";
import { Link } from "react-router-dom";

const TicketTr = ({ ticket, setImageModal, handleChangeStatus, history }) => {
  const [isHoverName, setIsHoverName] = useState(false);

  const handleMouseEnter = () => {
    setIsHoverName(true);
  };

  const handleMouseLeave = () => {
    setIsHoverName(false);
  };

  const isHoverNameStyles = {
    cursor: isHoverName ? "pointer" : "default",
    textDecoration: isHoverName ? "underline" : "none",
    color: isHoverName ? "#007bff" : "",
  };
  return (
    <Tr>
      <Td>
        <div className="d-flex justify-content-center align-items-center">
          <img
            src={
              ticket?.user?.profile_image?.url
                ? ticket?.user?.profile_image?.url
                : `https://ui-avatars.com/api/?name=${ticket?.user?.nickname}`
            }
            alt={ticket?.user?.nickname}
            onClick={() =>
              setImageModal({
                isOpen: true,
                image: ticket?.user?.profile_image?.url,
              })
            }
            style={{
              width: "60px",
              height: "60px",
              cursor: "pointer",
            }}
            className="img-thumbnail"
          />
        </div>
      </Td>
      <Td
        onClick={() => {
          history.push(`/users/${ticket?.user?.objectId}`);
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={isHoverNameStyles}
      >
        {ticket.user?.nickname}
      </Td>
      <Td>{ticket.user?.gender?.objectId === "a2bZa53s3l" ? "M" : "F"}</Td>
      <Td>{ticket.user?.email}</Td>
      <Td>{new Date(ticket.createdAt).toLocaleString()}</Td>
      <Td>{ticket.status === "pending" ? "Not Solved" : "Solved"}</Td>
      <Td>
        <div className="d-flex">
          {ticket?.status === "pending" && (
            <button
              type="button"
              title="Confirm"
              className="btn btn-md btn-primary waves-effect waves-light d-flex justify-content-center align-items-center "
              onClick={() => handleChangeStatus(ticket?.objectId)}
            >
              <i className="fas fa-check"></i>
            </button>
          )}
          <Link to={`/ticket/${ticket?.objectId}`}>
            <button
              type="button"
              title="Detail"
              className="btn btn-md btn-primary waves-effect waves-light d-flex justify-content-center align-items-center ms-2"
            >
              <i className="bx bxs-show font-size-10 "></i>
            </button>
          </Link>
        </div>
      </Td>
    </Tr>
  );
};

export default TicketTr;
