import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import Loading from "../../components/Common/Loading";

const GeneralReportTable = ({ reportData, loading, order, handleOrder }) => {
  return (
    <>
      <div className="table-rep-plugin">
        <div
          className="table-responsive mb-0"
          data-pattern="priority-columns"
          style={{
            position: "relative",
            height: "70vh",
          }}
        >
          <Table className="table table-striped table-bordered">
            <Thead>
              <Tr>
                <Th
                  onClick={() => handleOrder("DATES")}
                  style={{ cursor: "pointer" }}
                >
                  Date{" "}
                  <div className="d-inline-block">
                    <i
                      className="dripicons-arrow-thin-up"
                      style={{
                        opacity:
                          order.column === "DATES" && order.direction === "desc"
                            ? 1
                            : 0.6,
                      }}
                    ></i>
                    <i
                      className="dripicons-arrow-thin-down"
                      style={{
                        opacity:
                          order.column === "DATES" && order.direction === "asc"
                            ? 1
                            : 0.6,
                      }}
                    ></i>
                  </div>
                </Th>
                <Th
                  onClick={() => handleOrder("iOSDownload")}
                  style={{ cursor: "pointer" }}
                >
                  IOS Download{" "}
                  <div className="d-inline-block">
                    <i
                      className="dripicons-arrow-thin-up"
                      style={{
                        opacity:
                          order.column === "iOSDownload" &&
                            order.direction === "desc"
                            ? 1
                            : 0.6,
                      }}
                    ></i>
                    <i
                      className="dripicons-arrow-thin-down"
                      style={{
                        opacity:
                          order.column === "iOSDownload" &&
                            order.direction === "asc"
                            ? 1
                            : 0.6,
                      }}
                    ></i>
                  </div>
                </Th>
                <Th
                  onClick={() => handleOrder("iOSRegisteredUsers")}
                  style={{ cursor: "pointer" }}
                >
                  IOS Register{" "}
                  <div className="d-inline-block">
                    <i
                      className="dripicons-arrow-thin-up"
                      style={{
                        opacity:
                          order.column === "iOSRegisteredUsers" &&
                            order.direction === "desc"
                            ? 1
                            : 0.6,
                      }}
                    ></i>
                    <i
                      className="dripicons-arrow-thin-down"
                      style={{
                        opacity:
                          order.column === "iOSRegisteredUsers" &&
                            order.direction === "asc"
                            ? 1
                            : 0.6,
                      }}
                    ></i>
                  </div>
                </Th>
                <Th
                  onClick={() => handleOrder("androidDownload")}
                  style={{ cursor: "pointer" }}
                >
                  Android Download{" "}
                  <div className="d-inline-block">
                    <i
                      className="dripicons-arrow-thin-up"
                      style={{
                        opacity:
                          order.column === "androidDownload" &&
                            order.direction === "desc"
                            ? 1
                            : 0.6,
                      }}
                    ></i>
                    <i
                      className="dripicons-arrow-thin-down"
                      style={{
                        opacity:
                          order.column === "androidDownload" &&
                            order.direction === "asc"
                            ? 1
                            : 0.6,
                      }}
                    ></i>
                  </div>
                </Th>
                <Th
                  onClick={() => handleOrder("androidRegisteredUsers")}
                  style={{ cursor: "pointer" }}
                >
                  Android Register{" "}
                  <div className="d-inline-block">
                    <i
                      className="dripicons-arrow-thin-up"
                      style={{
                        opacity:
                          order.column === "androidRegisteredUsers" &&
                            order.direction === "desc"
                            ? 1
                            : 0.6,
                      }}
                    ></i>
                    <i
                      className="dripicons-arrow-thin-down"
                      style={{
                        opacity:
                          order.column === "androidRegisteredUsers" &&
                            order.direction === "asc"
                            ? 1
                            : 0.6,
                      }}
                    ></i>
                  </div>
                </Th>
                <Th
                  onClick={() => handleOrder("webDownload")}
                  style={{ cursor: "pointer" }}
                >
                  Web Visit{" "}
                  <div className="d-inline-block">
                    <i
                      className="dripicons-arrow-thin-up"
                      style={{
                        opacity:
                          order.column === "webDownload" &&
                            order.direction === "desc"
                            ? 1
                            : 0.6,
                      }}
                    ></i>
                    <i
                      className="dripicons-arrow-thin-down"
                      style={{
                        opacity:
                          order.column === "webDownload" &&
                            order.direction === "asc"
                            ? 1
                            : 0.6,
                      }}
                    ></i>
                  </div>
                </Th>
                <Th
                  onClick={() => handleOrder("webRegisteredUsers")}
                  style={{ cursor: "pointer" }}
                >
                  Web Register{" "}
                  <div className="d-inline-block">
                    <i
                      className="dripicons-arrow-thin-up"
                      style={{
                        opacity:
                          order.column === "webRegisteredUsers" &&
                            order.direction === "desc"
                            ? 1
                            : 0.6,
                      }}
                    ></i>
                    <i
                      className="dripicons-arrow-thin-down"
                      style={{
                        opacity:
                          order.column === "webRegisteredUsers" &&
                            order.direction === "asc"
                            ? 1
                            : 0.6,
                      }}
                    ></i>
                  </div>
                </Th>
                <Th>IOS In App</Th>
                <Th>IOS Subscription</Th>
                <Th>Recurring-Ios-Sub</Th>
                <Th>Android In App</Th>
                <Th>Android Subscription</Th>
                <Th>Recurring-Android-Sub</Th>
                <Th>Web In App</Th>
                <Th>Web Subscription</Th>
                <Th>Total Register</Th>
                <Th>Total In App</Th>
                <Th>Total Subscription</Th>
                <Th>Total Recurring</Th>
                <Th>Total Sales</Th>
              </Tr>
            </Thead>
            {loading ? (
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: 500,
                  height: 500,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Loading />
              </div>
            ) : (
              <Tbody>
                {reportData?.map((item, index) => {
                  return (
                    <Tr key={index}>
                      <Td>{item?.DATES}</Td>
                      <Td>{item?.iOSDownload}</Td>
                      <Td>{item?.iOSRegisteredUsers}</Td>
                      <Td>{item?.androidDownload}</Td>
                      <Td>{item?.androidRegisteredUsers}</Td>
                      <Td>{item?.webDownload}</Td>
                      <Td>{item?.webRegisteredUsers}</Td>
                      <Td>{item?.iosinappPurchase}</Td>
                      <Td>{item?.iossubPurchase}</Td>
                      <Td>{item?.ReSubReceiptIosPurchase}</Td>
                      <Td>{item?.androidinappPurchase}</Td>
                      <Td>{item?.androidsubPurchase}</Td>
                      <Td>{item?.ReSubReceiptAndroidPurchase}</Td>
                      <Td>{item?.webinappPurchase}</Td>
                      <Td>{item?.websubPurchase}</Td>
                      <Td>
                        {(item?.iOSRegisteredUsers || 0) +
                          (item?.androidRegisteredUsers || 0) +
                          (item?.webRegisteredUsers || 0)}
                      </Td>
                      <Td>
                        {(+item?.iosinappPurchase?.split(" ")[0] || 0) +
                          (+item?.androidinappPurchase?.split(" ")[0] || 0) +
                          (+item?.webinappPurchase?.split(" ")[0] || 0)}{" "}
                        {`(${(+item?.iosinappPurchase
                          ?.split(" ")[1]
                          .replace(/[()]/g, "") || 0) +
                          (+item?.androidinappPurchase
                            ?.split(" ")[1]
                            .replace(/[()]/g, "") || 0) +
                          (+item?.webinappPurchase
                            ?.split(" ")[1]
                            .replace(/[()]/g, "") || 0)
                          })`}
                      </Td>
                      <Td>
                        {(+item?.iossubPurchase?.split(" ")[0] || 0) +
                          (+item?.androidsubPurchase?.split(" ")[0] || 0) +
                          (+item?.websubPurchase?.split(" ")[0] || 0)}{" "}
                        {`(${(+item?.iossubPurchase
                          ?.split(" ")[1]
                          .replace(/[()]/g, "") || 0) +
                          (+item?.androidsubPurchase
                            ?.split(" ")[1]
                            .replace(/[()]/g, "") || 0) +
                          (+item?.websubPurchase
                            ?.split(" ")[1]
                            .replace(/[()]/g, "") || 0)
                          })`}
                      </Td>
                      <Td>
                        {(+item?.ReSubReceiptAndroidPurchase?.split(" ")[0] || 0) +
                          (+item?.ReSubReceiptIosPurchase?.split(" ")[0] || 0)}{" "}
                        {`(${(+item?.ReSubReceiptAndroidPurchase
                          ?.split(" ")[1]
                          .replace(/[()]/g, "") || 0) +
                          (+item?.ReSubReceiptIosPurchase
                            ?.split(" ")[1]
                            .replace(/[()]/g, "") || 0)
                          })`}
                      </Td>
                      <Td>
                        {(+item?.iosinappPurchase?.split(" ")[0] || 0) +
                          (+item?.iossubPurchase?.split(" ")[0] || 0) +
                          (+item?.androidinappPurchase?.split(" ")[0] || 0) +
                          (+item?.androidsubPurchase?.split(" ")[0] || 0) +
                          (+item?.webinappPurchase?.split(" ")[0] || 0) +
                          (+item?.websubPurchase?.split(" ")[0] || 0)}{" "}
                        {`(${(+item?.iosinappPurchase
                          ?.split(" ")[1]
                          .replace(/[()]/g, "") || 0) +
                          (+item?.iossubPurchase
                            ?.split(" ")[1]
                            .replace(/[()]/g, "") || 0) +
                          (+item?.androidinappPurchase
                            ?.split(" ")[1]
                            .replace(/[()]/g, "") || 0) +
                          (+item?.androidsubPurchase
                            ?.split(" ")[1]
                            .replace(/[()]/g, "") || 0) +
                          (+item?.webinappPurchase
                            ?.split(" ")[1]
                            .replace(/[()]/g, "") || 0) +
                          (+item?.websubPurchase
                            ?.split(" ")[1]
                            .replace(/[()]/g, "") || 0)
                          })`}
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            )}
          </Table>
        </div>
      </div>
    </>
  );
};

export default GeneralReportTable;
