import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
// import Rightbar from "../CommonForBoth/Rightbar";
import {
  changeLayout,
  changeSidebarTheme,
  changeSidebarType,
  changeTopbarTheme,
  changeLayoutWidth,
} from "../../store/actions";
import { connect } from "react-redux";
// import { useHistory, useLocation } from "react-router-dom";

function Layout(props) {
  // const { pathname } = useLocation();
  // const history = useHistory();
  const [isMobile, setIsMobile] = useState(
    /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
  );
  const toggleMenuCallback = () => {
    if (props.leftSideBarType === "default") {
      props.changeSidebarType("condensed", isMobile);
    } else if (props.leftSideBarType === "condensed") {
      props.changeSidebarType("default", isMobile);
    }
  };
  // const capitalizeFirstLetter = (string) => {
  //   return string.charAt(1).toUpperCase() + string.slice(2);
  // };
  useEffect(() => {
    if (props.isPreloader === true) {
      document.getElementById("preloader").style.display = "block";
      document.getElementById("status").style.display = "block";

      setTimeout(function () {
        document.getElementById("preloader").style.display = "none";
        document.getElementById("status").style.display = "none";
      }, 2500);
    } else {
      document.getElementById("preloader").style.display = "none";
      document.getElementById("status").style.display = "none";
    }

    // Scroll Top to 0
    window.scrollTo(0, 0);
    const title = window.location.pathname;
    let currentPage =
      title.charAt(1).toUpperCase() + title.slice(2).split("/")[0];
    currentPage = currentPage.replace(/-/g, " ");
    document.title = currentPage + " | Admin Dashboard";
    if (props.leftSideBarTheme) {
      props.changeSidebarTheme(props.leftSideBarTheme);
    }

    props.changeLayout("detached");

    if (props.layoutWidth) {
      props.changeLayoutWidth(props.layoutWidth);
    }

    if (props.leftSideBarType) {
      props.changeSidebarType(props.leftSideBarType);
    }
    if (props.topbarTheme) {
      props.changeTopbarTheme(props.topbarTheme);
    }
  }, []);
  return (
    <>
      <div id="preloader">
        <div id="status">
          <div className="spinner-chase">
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div id="layout-wrapper">
          <Header toggleMenuCallback={toggleMenuCallback} />
          <Sidebar
            theme={props?.leftSideBarTheme}
            type={props?.leftSideBarType}
            isMobile={isMobile}
          />
          <div className="main-content">
            {props.children}
            <Footer />
          </div>
        </div>
      </div>
      {/* {props.showRightSidebar ? <Rightbar /> : null} */}
    </>
  );
}
Layout.propTypes = {
  changeLayoutWidth: PropTypes.func,
  changeSidebarTheme: PropTypes.func,
  changeSidebarType: PropTypes.func,
  changeTopbarTheme: PropTypes.func,
  children: PropTypes.object,
  isPreloader: PropTypes.any,
  layoutWidth: PropTypes.any,
  leftSideBarTheme: PropTypes.any,
  leftSideBarType: PropTypes.any,
  location: PropTypes.object,
  showRightSidebar: PropTypes.any,
  topbarTheme: PropTypes.any,
};

const mapStatetoProps = (state) => {
  return {
    ...state.Layout,
  };
};
export default connect(mapStatetoProps, {
  changeLayout,
  changeSidebarTheme,
  changeSidebarType,
  changeTopbarTheme,
  changeLayoutWidth,
})(Layout);
