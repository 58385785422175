import { Table, Thead, Tbody, Tr, Th } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import Swal from "sweetalert2";
import { assignAsTutor } from "../../../service/parse-service";
import Loading from "../../../components/Common/Loading";
import Parse from "parse";
import PendingTutorTr from "./PendingTutorTr";

const PendingTutorTable = ({
  pendingTutorsData,
  loading,
  handleOrder,
  order,
  history,
}) => {
  const handleAssignAsTutor = (tutorId) => {
    Swal.fire({
      title: "Are you sure you want to confirm?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await assignAsTutor(tutorId);
          Swal.fire(response);
          window.location.reload();
        } catch (e) {
          Swal.fire(e.message);
        }
      }
    });
  };

  const handleRejectTutor = async (tutorId) => {
    try {
      const user = await new Parse.Query(Parse.User).get(tutorId);
      const totalApplicationCount = await new Parse.Query(
        "TutorApplication"
      ).count();
      const createFakeUserPointer = {
        __type: "Pointer",
        className: "_User",
        objectId: tutorId + `old${totalApplicationCount}`,
      };
      const tutorApp = await new Parse.Query("TutorApplication")
        .equalTo("user", user)
        .first();
      tutorApp.set("user", createFakeUserPointer);
      tutorApp.set("isDelete", true);
      tutorApp.set("isRejected", true);
      return await tutorApp.save();
    } catch (e) {
      console.log(e);
    } finally {
      window.location.reload();
    }
  };

  return (
    <>
      <div className="table-rep-plugin">
        <div
          className="table-responsive mb-0 "
          data-pattern="priority-columns"
          style={{
            position: "relative",
            height: "50vh",
          }}
        >
          <Table className="table table-striped table-bordered">
            <Thead>
              <Tr>
                <Th
                  onClick={() => handleOrder("createdAt")}
                  style={{ cursor: "pointer" }}
                >
                  Date{" "}
                  <div className="d-inline-block">
                    <i
                      className="dripicons-arrow-thin-up"
                      style={{
                        opacity:
                          order.column === "createdAt" &&
                          order.direction === "desc"
                            ? 1
                            : 0.6,
                      }}
                    ></i>
                    <i
                      className="dripicons-arrow-thin-down"
                      style={{
                        opacity:
                          order.column === "createdAt" &&
                          order.direction === "asc"
                            ? 1
                            : 0.6,
                      }}
                    ></i>
                  </div>
                </Th>
                <Th>Fullname</Th>
                <Th>Username</Th>
                <Th>Email</Th>
                <Th>Mobile</Th>
                <Th>Birthdate</Th>
                <Th>Gender</Th>
                <Th>Known Languages</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            {loading ? (
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: 500,
                  height: 500,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Loading />
              </div>
            ) : (
              <Tbody>
                {pendingTutorsData?.map((pendingTutor) => (
                  <PendingTutorTr
                    key={pendingTutor.objectId}
                    pendingTutor={pendingTutor}
                    handleAssignAsTutor={handleAssignAsTutor}
                    handleRejectTutor={handleRejectTutor}
                    history={history}
                  />
                ))}
              </Tbody>
            )}
          </Table>
        </div>
      </div>
    </>
  );
};

export default PendingTutorTable;
