import Parse from "parse";
import { toast } from "react-toastify";

Parse.initialize(
  process.env.REACT_APP_PARSE_APPLICATION_ID,
  process.env.REACT_APP_PARSE_JAVASCRIPT_KEY
);
Parse.serverURL = process.env.REACT_APP_PARSE_HOST_URL;

const Gender = Parse.Object.extend("Gender");

export const login = async (username, password) => {
  return await Parse.User.logIn(username, password);
};

export const logout = async () => {
  return await Parse.User.logOut();
};

export const fetchCurrentUser = async () => {
  return await Parse.User.current()?.fetch();
};

export const getUsers = async () => {
  try {
    let jsonItems = [];
    const role = await new Parse.Query(Parse.Role)
      .equalTo("name", "user")
      .first();

    const results = await role.relation("users").query().includeAll().find();

    results.forEach((result) => {
      jsonItems.push(result.toJSON());
    });

    return jsonItems;
  } catch (e) {
    console.log(e);
    return [];
  }
};

export const getTutorEarningBySpecificDate = async (
  startTime,
  endTime,
  searchQuery,
  currentPage,
  activitiesPerPage,
  order
) => {
  try {
    let activities = [];
    const currentUser = await fetchCurrentUser();
    const TutorEarning = Parse.Object.extend("TutorEarning");
    const query = new Parse.Query(TutorEarning);
    query.greaterThanOrEqualTo("startTime", startTime);
    query.lessThanOrEqualTo("endTime", endTime);
    query.includeAll();
    query.descending("createdAt");

    if (order && order.column) {
      order.direction === "desc"
        ? query.descending(order.column)
        : query.ascending(order.column);
    }

    if (activitiesPerPage && currentPage) {
      query.limit(activitiesPerPage);
      query.skip((currentPage - 1) * activitiesPerPage);
    }

    if (searchQuery) {
      query.matches(["fullname"], searchQuery, "i");
    }

    const totalCount = await query.count();

    if (currentUser?.get("username") !== "admin") {
      const users = await new Parse.Query(Parse.User)
        .containedIn("objectId", currentUser?.get("authority_users"))
        .find();
      const nicknames = users.map((user) => user.get("nickname"));
      query.containedIn("fullname", nicknames);
    }
    const results = await query.find();
    results.forEach((result) => {
      activities.push(result.toJSON());
    });

    return {
      activities,
      totalCount,
    };
  } catch (e) {
    console.log(e);
    return [];
  }
};

export const getTutorEarning = async (id) => {
  try {
    let jsonItems = [];
    const TutorEarning = Parse.Object.extend("TutorEarning");
    const query = new Parse.Query(TutorEarning);
    query.equalTo("tutor", id);
    query.includeAll();
    const results = await query.find();
    results.forEach((result) => {
      jsonItems.push(result.toJSON());
    });

    return jsonItems.reverse();
  } catch (e) {
    console.log(e);
    return [];
  }
};

export const getCategories = async () => {
  try {
    let categoriesJson = [];
    const query = new Parse.Query("Category");
    query.includeAll();
    const result = await query.find();
    result.forEach((category) => {
      categoriesJson.push(category.toJSON());
    });
    return categoriesJson;
  } catch (e) {
    console.log(e);
  }
};

export const getProducts = async () => {
  try {
    let productsJson = [];
    const query = new Parse.Query("ProductInfo");
    query.includeAll();

    const result = await query.find();
    result.forEach((category) => {
      productsJson.push(category.toJSON());
    });
    return productsJson;
  } catch (e) {
    console.log(e);
  }
};

export const getSingleUser = async (id) => {
  const query = new Parse.Query("_User");
  query.equalTo("objectId", id);
  query.includeAll();
  const result = await query.first();
  return result.toJSON();
};

export const deleteUser = async (id) => {
  const query = new Parse.Query("_User");
  query.equalTo("objectId", id);
  const result = await query.first();
  result.set("isDeleted", true);
  return await result.save();
};

export const deleteTutor = async (id) => {
  const data = await Parse.Cloud.run("DeleteTutor", { user_id: id });
  console.log(data);
  return data;
};

export const updateUser = async (id, user) => {
  const query = new Parse.Query("_User");
  query.equalTo("objectId", id);
  const result = await query.find();
  const user1 = result[0];
  if (user?.profile_image) {
    if (user?.profile_image?.lastModified) {
      const file = new Parse.File(
        user?.profile_image.name,
        user?.profile_image,
        "image/png"
      );
      const el = await file.save();
      user1.set("profile_image", el);
    } else {
      user1.set("profile_image", user?.profile_image);
    }
  }
  user1.set("nickname", user?.nickname);
  user1.set("gender", {
    __type: "Pointer",
    className: "Gender",
    objectId: user?.gender,
  });
  user1.set("email", user?.email);
  return await user1.save();
};

export const getTutorApplication = async (
  order,
  searchQuery,
  currentPage,
  pendingTutorsPerPage
) => {
  try {
    let pendingTutors = [];
    const query = new Parse.Query("TutorApplication");
    query.equalTo("isDelete", false);
    query.descending("createdAt");
    query.includeAll();

    if (order && order.column) {
      order.direction === "desc"
        ? query.descending(order.column)
        : query.ascending(order.column);
    }

    if (pendingTutorsPerPage && currentPage) {
      query.limit(pendingTutorsPerPage);
      query.skip((currentPage - 1) * pendingTutorsPerPage);
    }

    if (searchQuery) {
      query.matchesQuery(
        "user",
        new Parse.Query("_User").matches("nickname", searchQuery, "i")
      );
    }

    const totalCount = await query.count();

    const results = await query.find();
    results.forEach((item) => {
      pendingTutors.push(item.toJSON());
    });

    return {
      pendingTutors,
      totalCount,
    };
  } catch (e) {
    console.log(e);
    return [];
  }
};

export const getTutor = async (id) => {
  try {
    const query = new Parse.Query("TutorApplication");
    query.equalTo("objectId", id);
    const result = await query.find();
    return result[0].toJSON();
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const assignAsTutor = async (userId) => {
  return await Parse.Cloud.run("AssignAsTutor", { user_id: userId });
};

export const getTutors = async (
  currentPage,
  tutorsPerPage,
  searchQuery,
  order
) => {
  try {
    let tutors = [];
    const currentUser = await fetchCurrentUser();

    // const role = await new Parse.Query(Parse.Role)
    //   .equalTo("name", "tutor")
    //   .first();
    // const query = role
    //   .relation("users")
    //   .query()
    //   .includeAll()
    //   .notEqualTo("isDeleted", true);

    const query = new Parse.Query(Parse.User)
      .notEqualTo("isDeleted", true)
      .equalTo("isTutor", true);

    query.descending("createdAt");

    if (currentUser?.get("username") !== "admin") {
      query.containedIn("objectId", currentUser?.get("authority_users"));
    }

    if (order && order.column) {
      order.direction === "desc"
        ? query.descending(order.column)
        : query.ascending(order.column);
    }

    if (tutorsPerPage && currentPage) {
      query.limit(tutorsPerPage);
      query.skip((currentPage - 1) * tutorsPerPage);
    }

    if (searchQuery) {
      query.matches(["nickname"], searchQuery, "i");
    }

    const totalCount = await query.count();

    const results = await query.find();

    // const results2 = await query1.find();

    // console.log(results.length);
    // console.log(results2.length);

    // const r2 = results2.map((i) => i.id);

    // const list = results.filter((i) => !r2.includes(i.id));
    // console.log(list);

    results.forEach((result) => {
      tutors.push(result.toJSON());
    });
    return { tutors, totalCount };
  } catch (e) {
    console.log(e);
    return [];
  }
};

export const getUsersData = async (
  currentPage,
  usersPerPage,
  searchQuery,
  order
) => {
  try {
    let users = [];
    const query = new Parse.Query("_User");
    query.descending("createdAt");
    query.notEqualTo("isDeleted", true);

    if (order && order.column) {
      order.direction === "desc"
        ? query.descending(order.column)
        : query.ascending(order.column);
    }

    if (usersPerPage && currentPage) {
      query.limit(usersPerPage);
      query.skip((currentPage - 1) * usersPerPage);
    }

    if (searchQuery) {
      query.matches(["nickname"], searchQuery, "i");
    }

    const totalCount = await query.count();

    const results = await query.find();

    results.forEach((item) => {
      users.push(item.toJSON());
    });
    return { users, totalCount };
  } catch (e) {
    console.log(e);
    return [];
  }
};

export const getSessionHistoryAdmin = async (userId) => {
  return await Parse.Cloud.run("GetSessionHistoryAdmin", { user_id: userId });
};

export const getSupport = async (
  ticketStatusFilter,
  currentPage,
  ticketsPerPage,
  searchQuery,
  order
) => {
  try {
    let tickets = [];
    const query = new Parse.Query("Support");
    if (ticketStatusFilter) {
      query.equalTo("status", ticketStatusFilter);
    }
    query.includeAll();
    query.descending("createdAt");

    if (order && order.column) {
      order.direction === "desc"
        ? query.descending(order.column)
        : query.ascending(order.column);
    }

    if (ticketsPerPage && currentPage) {
      query.limit(ticketsPerPage);
      query.skip((currentPage - 1) * ticketsPerPage);
    }

    if (searchQuery) {
      query.matchesQuery(
        "user",
        new Parse.Query("_User").matches("nickname", searchQuery, "i")
      );
    }

    const totalCount = await query.count();
    const results = await query.find();
    results.forEach((result) => {
      tickets.push(result.toJSON());
    });
    return {
      tickets,
      totalCount,
    };
  } catch (e) {
    console.log(e);
  }
};

export const toggleTicketStatus = async (id) => {
  const query = new Parse.Query("Support");
  query.equalTo("objectId", id);
  const result = await query.find();
  const ticket = result[0];
  ticket.set("status", "confirmed");
  return await ticket.save();
};

export const getSingleSupport = async (id) => {
  const query = new Parse.Query("Support");
  query.equalTo("objectId", id);
  query.includeAll();
  const result = await query.find();
  return result[0].toJSON();
};

export const getPaypalPayment = async () => {
  try {
    let jsonItems = [];
    const query = new Parse.Query("PaypalPayment");
    const results = await query.find();
    results.forEach((item) => {
      jsonItems.push(item.toJSON());
    });
    return jsonItems.reverse();
  } catch (e) {
    console.log(e);
    return [];
  }
};

export const getTutorEarningReport = async (
  state,
  order,
  currentPage,
  tutorEarningPerPage,
  searchQuery
) => {
  try {
    const { startDate, endDate } = state[0];
    const currentUser = await fetchCurrentUser();
    let endTime = endDate;
    let items = [];
    const query = new Parse.Query("TutorEarningReport");
    query.descending("createdAt");
    query.limit(2000);

    if (order && order.column) {
      order.direction === "desc"
        ? query.descending(order.column)
        : query.ascending(order.column);
    }

    if (tutorEarningPerPage && currentPage) {
      query.limit(tutorEarningPerPage);
      query.skip((currentPage - 1) * tutorEarningPerPage);
    }

    if (searchQuery) {
      query.matches(["fullname"], searchQuery, "i");
    }

    if (+new Date(startDate) === +new Date(endDate)) {
      endTime = new Date(new Date(startDate).setHours(23, 59));
    }
    if (startDate) {
      query.greaterThanOrEqualTo("createdAt", startDate);
      query.lessThanOrEqualTo(
        "createdAt",
        new Date(new Date(endTime).setHours(23, 59))
      );
    }
    if (currentUser?.get("username") !== "admin") {
      const users = await new Parse.Query(Parse.User)
        .containedIn("objectId", currentUser?.get("authority_users"))
        .find();
      const nicknames = users.map((user) => user.get("nickname"));
      query.containedIn("fullname", nicknames);
    }
    const totalCount = await query.count();
    const results = await query.find();
    results.forEach((item) => {
      items.push(item.toJSON());
    });
    return { items, totalCount };
  } catch (e) {
    console.log(e);
    return [];
  }
};

export const getTutorEarningReportDetail = async (id) => {
  try {
    let jsonItems = [];
    const query = new Parse.Query("TutorEarningReport");
    query.equalTo("objectId", id);
    const results = await query.find();
    results.forEach((item) => {
      jsonItems.push(item.toJSON());
    });
    return jsonItems.reverse();
  } catch (e) {
    console.log(e);
    return [];
  }
};

export const getPlatformCount = async () => {
  try {
    let jsonItems = [];
    const query = new Parse.Query("PlatformCount");
    query.limit(3000);
    const results = await query.find();
    results.forEach((item) => {
      jsonItems.push(item.toJSON());
    });
    return jsonItems.reverse()[0];
  } catch (e) {
    console.log(e);
    return [];
  }
};

export const userBan = async (userId) => {
  return await Parse.Cloud.run("UserBan", {
    userId,
  });
};

export const userUnBan = async (userId) => {
  return await Parse.Cloud.run("UserUnban", {
    userId,
  });
};

export const changeUserAvailibility = async (userId, isAvailable) => {
  const user = await new Parse.Query(Parse.User).get(userId);
  user.set("available", isAvailable);
  return await user.save();
};

export const getGenders = async () => {
  const query = new Parse.Query(Gender);
  return await query.find();
};

export const getReport = async (
  currentPage,
  reportsPerPage,
  searchQuery,
  order
) => {
  try {
    let reports = [];
    const query = new Parse.Query("Report");
    query.includeAll();
    query.descending("createdAt");

    if (order && order.column) {
      order.direction === "desc"
        ? query.descending(order.column)
        : query.ascending(order.column);
    }

    if (reportsPerPage && currentPage) {
      query.limit(reportsPerPage);
      query.skip((currentPage - 1) * reportsPerPage);
    }

    if (searchQuery) {
      query.matchesQuery(
        "user",
        new Parse.Query("_User").matches("nickname", searchQuery, "i")
      );
    }

    const totalCount = await query.count();
    const results = await query.find();
    results.forEach((item) => {
      reports.push(item.toJSON());
    });
    return {
      reports,
      totalCount,
    };
  } catch (e) {
    console.log(e);
    return [];
  }
};

export const getReportDetail = async (id) => {
  try {
    const query = new Parse.Query("Report");
    query.equalTo("objectId", id);
    const result = await query.first();
    return result.toJSON();
  } catch (e) {
    console.log(e);
    return [];
  }
};

export const getUserReports = async (startDate, endDate) => {
  return await Parse.Cloud.run("UserReports", {
    startDate: startDate,
    endDate: endDate,
  });
};

export const getUserPurchases = async (startDate, endDate) => {
  return await Parse.Cloud.run("UserPurchases", {
    startDate: startDate,
    endDate: endDate,
  });
};

export const getUserCountry = async (startDate, endDate) => {
  return await Parse.Cloud.run("UserCountry", {
    startDate: startDate,
    endDate: endDate,
  });
};

export const getUserCountryPurchases = async (startDate, endDate) => {
  return await Parse.Cloud.run("UserCountryPurchases", {
    startDate: startDate,
    endDate: endDate,
  });
};

export const getUserPurchaseReports = async (state) => {
  return await Parse.Cloud.run("UserPurchaseReports", {
    before: state[0].endDate.toISOString().split("T")[0],
    after: state[0].startDate.toISOString().split("T")[0],
  });
};

export const getUserPurchaseCountryReports = async (state) => {
  const _endDate = new Date(state[0].endDate);
  _endDate.setDate(_endDate.getDate() + 1);

  const _startDate = new Date(state[0].startDate);
  _startDate.setDate(_startDate.getDate() + 1);

  return await Parse.Cloud.run("UserPurchaseReportsWithCountry", {
    before: _endDate,
    after: _startDate,
  });
};

export const getGeneralReport = async (startDate, endDate) => {
  let data = await Parse.Cloud.run("GeneralReport", {
    before: startDate,
    after: endDate,
  });

  return data;
};

export const getMissedCall = async (currentPage, missedCallPerPage, order) => {
  let missedCalls = [];
  const currentUser = await fetchCurrentUser();

  const query = new Parse.Query("SessionRequest").include("tutor", "user");
  query.equalTo("status", "Expired");
  query.descending("tutor.nickname");
  query.descending("updatedAt");

  if (order && order.column) {
    order.direction === "desc"
      ? query.descending(order.column)
      : query.ascending(order.column);
  }

  if (missedCallPerPage && currentPage) {
    query.limit(missedCallPerPage);
    query.skip((currentPage - 1) * missedCallPerPage);
  }

  // if (searchQuery) {
  //   query.matches(["fullname"], searchQuery, "i");
  // }

  const totalCount = await query.count();

  if (currentUser?.get("username") !== "admin") {
    query.containedIn("tutor", currentUser?.get("authority_users"));
  }

  const results = await query.find();

  results.forEach((item) => {
    missedCalls.push(item.toJSON());
  });

  return {
    missedCalls,
    totalCount,
  };
};

export const getTutorApplicationDetail = async (id) => {
  try {
    let jsonItems = [];
    //const currentUser = await fetchCurrentUser();
    const query = new Parse.Query("TutorApplication");
    query.equalTo("user", {
      __type: "Pointer",
      className: "_User",
      objectId: id,
    });
    query.includeAll();
    // if (currentUser.get("username") !== "admin") {
    //   query.containedIn("objectId", currentUser.get("authority_users"))
    // }
    const results = await query.find();
    results.forEach((item) => {
      jsonItems.push(item.toJSON());
    });
    return jsonItems.reverse();
  } catch (e) {
    console.log(e);
    return [];
  }
};

export const handleDeleteForAgent = async (IDs) => {
  try {
    if (IDs.length === 0) {
      toast.error("Please enter valid email");
      const err = new Error("invalid email");
      throw err;
    }
    const agent = await getAgentTutor();
    let authority_users = await getAuthorityUsers();
    const newAuthorityUsers = authority_users.filter(
      (element) => !IDs.includes(element)
    );
    agent.set("authority_users", newAuthorityUsers);
    await agent.save();
    toast.success("Deleted successfully");
    window.location.reload();
  } catch (error) {
    toast.error("Failed to delete");
    console.log(error);
  }
};

export const getAgentTutor = async () => {
  try {
    const role = await new Parse.Query(Parse.Role)
      .equalTo("name", "admin")
      .first();
    const query = role
      .relation("users")
      .query()
      .includeAll()
      .notEqualTo("isDeleted", true);
    query.equalTo("username", "agent");
    return query.first();
  } catch (e) {
    console.log(e);
    return {};
  }
};

export const getAuthorityUsers = async () => {
  return (await getAgentTutor()).get("authority_users");
};

export const saveNewAuthorityUsers = async (IDs) => {
  if (IDs.length === 0) {
    toast.error("Please enter valid email");
    const err = new Error("invalid email");
    throw err;
  }
  const currentAuthorityUsers = await getAuthorityUsers();
  const newAuthorityUsers = new Set(currentAuthorityUsers.concat(IDs));
  if (newAuthorityUsers.size === currentAuthorityUsers.length) {
    toast.error(`The following IDs already exist`);
    const err = new Error("Duplicate IDs");
    throw err;
  }
  (await getAgentTutor())
    .set("authority_users", Array.from(newAuthorityUsers))
    .save();
  toast.success("Added successfully");
  window.location.reload();
};

export const getObjIds = async (emailList) => {
  const users = await new Parse.Query(Parse.User)
    .containedIn("email", emailList)
    .find();
  return users.map((u) => u.id);
};

export const getUsersForStatistics = async (state) => {
  try {
    const { startDate, endDate } = state[0];
    let endTime = endDate;
    let users = [];
    const query = new Parse.Query("_User");
    query.notEqualTo("isDeleted", true);
    query.limit(9999);
    if (+new Date(startDate) === +new Date(endDate)) {
      endTime = new Date(new Date(startDate).setHours(23, 59));
    }
    if (startDate) {
      query.greaterThanOrEqualTo("createdAt", startDate);
      query.lessThanOrEqualTo(
        "createdAt",
        new Date(new Date(endTime).setHours(23, 59))
      );
    }
    const results = await query.find();
    results.forEach((item) => {
      users.push(item.toJSON());
    });
    return users;
  } catch (e) {
    console.log(e);
    return [];
  }
};

export const getProductLog = async (
  startTime,
  endTime,
  searchQuery,
  productLogPerPage,
  currentPage,
  salesStatusOption,
  order
) => {
  try {
    let items = [];

    const query = new Parse.Query("ProductLog");
    query.includeAll();
    query.limit(2000);
    query.descending("createdAt");

    if (order && order.column) {
      console.log(order);
      order.direction === "desc"
        ? query.descending(order.column)
        : query.ascending(order.column);
    }

    if (startTime && endTime) {
      query.greaterThanOrEqualTo("createdAt", startTime);
      query.lessThanOrEqualTo("createdAt", endTime);
    }

    if (salesStatusOption && salesStatusOption !== "All") {
      query.equalTo("productType", salesStatusOption);
    }

    if (productLogPerPage && currentPage) {
      query.limit(productLogPerPage);
      query.skip((currentPage - 1) * productLogPerPage);
    }

    if (searchQuery) {
      query.matchesQuery(
        "user",
        new Parse.Query("_User").matches("nickname", searchQuery, "i")
      );
    }

    const totalCount = await query.count();
    const results = await query.find();

    results.forEach((item) => {
      items.push(item.toJSON());
    });

    return { items, totalCount };
  } catch (e) {
    console.log(e);
    return [];
  }
};

export const getPreRegisterRecord = async (state) => {
  try {
    const { startDate, endDate } = state[0];
    let endTime = endDate;
    let items = [];
    const query = new Parse.Query("PreRegisterRecord");
    query.limit(9999);
    if (+new Date(startDate) === +new Date(endDate)) {
      endTime = new Date(new Date(startDate).setHours(23, 59));
    }
    if (startDate) {
      query.greaterThanOrEqualTo("createdAt", startDate);
      query.lessThanOrEqualTo(
        "createdAt",
        new Date(new Date(endTime).setHours(23, 59))
      );
    }
    const results = await query.find();
    results.forEach((item) => {
      items.push(item.toJSON());
    });
    return items;
  } catch (e) {
    console.log(e);
    return [];
  }
};

export const getProductLogForStatistics = async (state) => {
  try {
    const { startDate, endDate } = state[0];
    let endTime = endDate;
    let items = [];
    const query = new Parse.Query("ProductLog");
    query.limit(9999);
    if (+new Date(startDate) === +new Date(endDate)) {
      endTime = new Date(new Date(startDate).setHours(23, 59));
    }
    if (startDate) {
      query.greaterThanOrEqualTo("createdAt", startDate);
      query.lessThanOrEqualTo(
        "createdAt",
        new Date(new Date(endTime).setHours(23, 59))
      );
    }
    const results = await query.find();
    results.forEach((item) => {
      items.push(item.toJSON());
    });
    return items;
  } catch (e) {
    console.log(e);
    return [];
  }
};
