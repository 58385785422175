import { useState } from "react";
import { Tr, Td } from "react-super-responsive-table";

const ActivityTr = ({ activity, currentUserData, history }) => {
  const [isHoverTutorName, setIsHoverTutorName] = useState(false);
  const [isHoverUserName, setIsHoverUserName] = useState(false);

  const isHoverNameStyles = {
    cursor: isHoverTutorName || isHoverUserName ? "pointer" : "default",
    textDecoration: isHoverTutorName || isHoverUserName ? "underline" : "none",
    color: isHoverTutorName || isHoverUserName ? "#007bff" : "",
  };

  return (
    <Tr>
      <Td
        onClick={() => {
          history.push(`/users/${activity.tutor}`);
        }}
        onMouseEnter={() => setIsHoverTutorName(true)}
        onMouseLeave={() => setIsHoverTutorName(false)}
        style={isHoverTutorName ? isHoverNameStyles : {}}
      >
        {activity.fullname}
      </Td>
      <Td
        onClick={() => {
          history.push(`/users/${activity.user?.objectId}`);
        }}
        onMouseEnter={() => setIsHoverUserName(true)}
        onMouseLeave={() => setIsHoverUserName(false)}
        style={isHoverUserName ? isHoverNameStyles : {}}
      >
        {activity.user?.nickname}
      </Td>
      <Td>{new Date(activity.startTime?.iso).toLocaleString()}</Td>
      <Td>{activity.total_seconds}</Td>
      {currentUserData?.get("username") === "admin" && (
        <Td>
          {activity.isCall ? `${activity.total_minutes * 60} Coins` : "-"}
        </Td>
      )}

      <Td>{!activity.isCall ? `$ ${activity.amount}` : "-"}</Td>
      <Td>{isNaN(activity.amount) ? "-" : `$ ${activity.amount}`}</Td>
    </Tr>
  );
};

export default ActivityTr;
