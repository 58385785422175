import { useState } from "react";
import PuffLoader from "react-spinners/PuffLoader";
import { css } from "@emotion/react";
function Loading({ loading }) {
  const [color] = useState("#36D7B7");
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;
  return (
    <div className="d-flex justify-content-center ">
      <PuffLoader color={color} loading={loading} css={override} size={150} />
    </div>
  );
}

export default Loading;
