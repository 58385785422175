import { Row, Col, CardBody, Card, CardTitle } from "reactstrap";
import { DashboardInfo } from "../../components/DashboardInfo";
import { DashboardInfoUsers } from "../../components/DashboardInfoUsers"
import { DashboardInfoSubs } from "../../components/DashboardInfoSubs"

//import apexChart
// import LineApexChart from "../../utils/AllCharts/apex/chartapex";
// import DashedLine from "../../utils/AllCharts/apex/dashedLine";
// import SplineArea from "../../utils/AllCharts/apex/SplineArea";
// import Apaexlinecolumn from "../../utils/AllCharts/apex/apaexlinecolumn";
// import ColumnWithDataLabels from "../../utils/AllCharts/apex/ColumnWithDataLabels";
// import BarChart from "../../utils/AllCharts/apex/barchart";
// import LineColumnArea from "../../utils/AllCharts/apex/LineColumnArea";
// import RadialChart from "../../utils/AllCharts/apex/RadialChart";
// //import eChart
// import Doughnut from "../../utils/AllCharts/echart/doughnutchart";
import PiePlatform from "../../utils/AllCharts/echart/piechart";

// import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

const Dashboard = () => {
  return (
    <>
      <div className="page-content">
        <Breadcrumbs title="Dashboard" breadcrumbItem="Dashboard" />

        <Row>
          <Col lg="6">
            <Card style={{ height: "300px" }}>
              <CardBody>
                <CardTitle>User Statistics</CardTitle>
                <DashboardInfoUsers />
              </CardBody>
            </Card>
          </Col>
          <Col lg={6}>
            <Card style={{ height: "300px" }}>
              <CardBody>
                <CardTitle>Purchase/Subscription Statistics</CardTitle>
                <DashboardInfoSubs />
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col lg="6">
            <Card style={{ height: "420px" }}>
              <CardBody>
                <CardTitle>Langugo Statistics</CardTitle>
                <DashboardInfo />
              </CardBody>
            </Card>
          </Col>
          {/* <Col lg="6">
            <Card>
              <CardBody>
                <CardTitle>Doughnut Chart</CardTitle>
                <div id="doughnut-chart" className="e-chart">
                  <Doughnut />
                </div>
              </CardBody>
            </Card>
          </Col> */}
          <Col lg="6">
            <Card>
              <CardBody>
                <CardTitle>Platforms</CardTitle>
                <div id="pie-chart" className="e-chart">
                  <PiePlatform />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {/* <Row>
          <Col lg={6}>
            <Card>
              <CardBody>
                <CardTitle className="h4 mb-4"> Spline Area </CardTitle>
                <SplineArea />
              </CardBody>
            </Card>
          </Col>
          <Col lg={6}>
            <Card>
              <CardBody>
                <CardTitle className="h4 mb-4"> Column Chart </CardTitle>
                <Apaexlinecolumn />
              </CardBody>
            </Card>
          </Col>
        </Row> */}

        {/* <Row>
          <Col lg={6}>
            <Card>
              <CardBody>
                <CardTitle className="h4 mb-4">
                  Column with Data Labels{" "}
                </CardTitle>
                <ColumnWithDataLabels />
              </CardBody>
            </Card>
          </Col>
          <Col lg={6}>
            <Card>
              <CardBody>
                <CardTitle className="h4 mb-4">Bar Chart</CardTitle>
                <BarChart />
              </CardBody>
            </Card>
          </Col>
        </Row> */}

        {/* <Row>
          <Col lg={6}>
            <Card>
              <CardBody>
                <CardTitle className="h4 mb-4">
                  Line, Column & Area Chart{" "}
                </CardTitle>
                <LineColumnArea />
              </CardBody>
            </Card>
          </Col>
          <Col lg={6}>
            <Card>
              <CardBody>
                <CardTitle className="h4 mb-4">Radial Chart</CardTitle>
                <RadialChart />
              </CardBody>
            </Card>
          </Col>
        </Row> */}
      </div>
    </>
  );
};

export default Dashboard;
