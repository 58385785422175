import { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  FormGroup,
  Input,
} from "reactstrap";
import { ToastContainer } from "react-toastify";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "../../assets/scss/datatables.scss";
import { getTutors, fetchCurrentUser } from "../../service/parse-service";
import AddAuthorityUsersModal from "../../components/Modal/AddAuthorityUsersModal";
import RemoveAuthorityUsersModal from "../../components/Modal/RemoveAuthorityUsersModal";
import TablePagination from "../../components/TablePagination";
import TutorsTable from "./components/TutorsTable";
import ImageModal from "../../components/Modal/ImageModal";

const Tutors = ({ history }) => {
  const [loading, setLoading] = useState(false);
  const [currentUserData, setCurrentUserData] = useState(null);
  const [addAuthorityModal, setAddAuthorityModal] = useState(false);
  const [removeAuthorityModal, setRemoveAuthorityModal] = useState(false);
  const [imageModal, setImageModal] = useState({
    isOpen: false,
    image: "",
  });
  const [searchQuery, setSearchQuery] = useState("");
  const [totalEarnings, setTotalEarnings] = useState(0);
  const [totalPayments, setTotalPayments] = useState(0);
  const [order, setOrder] = useState({
    column: "",
    direction: "",
  });
  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [tutorsPerPage] = useState(10);
  const [totalTutors, setTotalTutors] = useState(0);
  const [tutorsData, setTutorsData] = useState([]);

  const handleOrder = (column) => {
    let direction = "asc";
    if (order.column === column && order.direction === "asc") {
      direction = "desc";
    }
    setOrder({ column, direction });
  };

  useEffect(() => {
    fetchCurrentUser().then((currentUser) => {
      setCurrentUserData(currentUser);
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const fetchTutors = async () => {
      setLoading(true);

      let results = await getTutors(
        currentPage,
        tutorsPerPage,
        searchQuery,
        order
      );

      if (currentPage > 1 && results.tutors.length === 0) {
        setCurrentPage(1);
      }

      setTotalTutors(results.totalCount);
      setTutorsData(results);

      let value1 = 0;
      let value2 = 0;
      results.tutors.forEach((item) => {
        if (item.totalEarning) {
          value1 += item?.totalEarning;
        }
        if (item.earning) {
          value2 += item?.earning;
        }
      });

      setTotalEarnings(value1);
      setTotalPayments(value2);

      setLoading(false);
    };
    fetchTutors();
  }, [currentPage, tutorsPerPage, searchQuery, order]);

  return (
    <>
      <ToastContainer />
      <AddAuthorityUsersModal
        modal={addAuthorityModal}
        setModal={setAddAuthorityModal}
      />
      <RemoveAuthorityUsersModal
        modal={removeAuthorityModal}
        setModal={setRemoveAuthorityModal}
      />
      <ImageModal imageModal={imageModal} setImageModal={setImageModal} />
      <div className="page-content">
        <Breadcrumbs title="Dashboard" breadcrumbItem="Tutors" />
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <CardTitle className="mb-2">Tutors</CardTitle>

                {currentUserData?.get("username") === "admin" && (
                  <>
                    <button
                      type="button"
                      className="btn btn-md btn-primary waves-effect waves-light mb-3"
                      onClick={() => setAddAuthorityModal(true)}
                    >
                      Add Tutor To Authority List
                    </button>

                    <button
                      type="button"
                      className="btn btn-md btn-danger waves-effect waves-light mb-3 ms-2"
                      onClick={() => setRemoveAuthorityModal(true)}
                    >
                      Remove Tutor To Authority List
                    </button>
                  </>
                )}

                <div className="d-flex mb-3 justify-content-between align-items-center">
                  <span>
                    {searchQuery && (
                      <span>
                        Search results for:{" "}
                        <span className="fw-bold">{searchQuery}</span>
                      </span>
                    )}
                  </span>
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <FormGroup className="ms-auto">
                      <Input
                        type="text"
                        placeholder="Search"
                        onChange={(e) => {
                          let target = e.target;
                          setTimeout(() => {
                            setSearchQuery(target.value);
                          }, 1000);
                        }}
                      />
                    </FormGroup>
                  </Form>
                </div>

                <TutorsTable
                  tutorsData={tutorsData}
                  loading={loading}
                  totalEarnings={totalEarnings}
                  totalPayments={totalPayments}
                  handleOrder={handleOrder}
                  order={order}
                  setImageModal={setImageModal}
                  history={history}
                />
                <TablePagination
                  dataPerPage={tutorsPerPage}
                  totalData={totalTutors}
                  setCurrentPage={setCurrentPage}
                  currentPage={currentPage}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Tutors;
