import PropTypes from "prop-types";

// class NonAuthLayout extends Component {
//   constructor(props) {
//     super(props)
//     this.state = {}
//     this.capitalizeFirstLetter.bind(this)
//   }

//   capitalizeFirstLetter = string => {
//     return string.charAt(1).toUpperCase() + string.slice(2)
//   }

//   componentDidMount() {
//     let currentage = this.capitalizeFirstLetter(this.props.location.pathname)

//     document.title =
//       currentage + " | Qovex - Responsive Bootstrap 5 Admin Dashboard"
//   }
//   render() {
//     return <React.Fragment>{this.props.children}</React.Fragment>
//   }
// }

// NonAuthLayout.propTypes = {
//   children: PropTypes.any,
//   location: PropTypes.object
// }

// export default withRouter(NonAuthLayout)

const NonAuthLayout = ({ children }) => {
  // useEffect(() => {
  //   let currentage = this.capitalizeFirstLetter(this.props.location.pathname);

  //   document.title =
  //     currentage + " | Qovex - Responsive Bootstrap 5 Admin Dashboard";
  // }, []);

  return <>{children}</>;
};
NonAuthLayout.propTypes = {
  children: PropTypes.any,
  location: PropTypes.object,
};
export default NonAuthLayout;
