import { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-datepicker/dist/react-datepicker.css";
import { Card, CardBody, Col, Row, Form } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import Loading from "../../../components/Common/Loading";
import {
  getSingleUser,
  updateUser,
  getGenders,
  deleteUser,
  userBan,
  userUnBan,
} from "../../../service/parse-service";
import Swal from "sweetalert2";

const UserDetail = ({ history }) => {
  const [loading, setLoading] = useState(false);
  const [updatedUserData, setUpdatedUserData] = useState({
    nickname: "",
    gender: "",
    email: "",
    profile_image: "",
  });
  const [genders, setGenders] = useState([]);
  const [temporaryProfileImage, setTemporaryProfileImage] = useState(null);

  console.log(history);

  const handleImagePreview = (e) => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      setUpdatedUserData({ ...updatedUserData, profile_image: file });
      setTemporaryProfileImage(URL.createObjectURL(file));
    }
  };

  const id =
    window.location.pathname.split("/")[
      window.location.pathname.split("/").length - 1
    ];

  const fetchSingleUser = async () => {
    setLoading(true);
    try {
      let result = await getSingleUser(id);
      const genders = await getGenders();
      setGenders(genders);

      setUpdatedUserData({
        ...result,
        gender: result?.gender?.objectId === "a2bZa53s3l" ? "Male" : "Female",
      });
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchSingleUser();
    //eslint-disable-next-line
  }, [id]);

  const handleUpdate = (e) => {
    setUpdatedUserData({
      ...updatedUserData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const userToUpdate = {
        ...updatedUserData,
      };
      setLoading(true);
      await updateUser(id, userToUpdate);
      setLoading(false);
      toast.success("User updated successfully");
    } catch (e) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  const handleDeleteUser = (userId) => {
    Swal.fire({
      title: "Are you sure you want to delete this user?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          Swal.fire("User deleted.");
          await deleteUser(userId);
          history.goBack();
        } catch (e) {
          Swal.fire(e.message);
        }
      }
    });
  };

  const handleBan = (userId) => {
    Swal.fire({
      title: "Are you sure you want to ban this user?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ban",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          Swal.fire("User Banned.");
          await userBan(userId);
          window.location.reload();
        } catch (e) {
          Swal.fire(e.message);
        }
      }
    });
  };

  const handleUnban = (userId) => {
    Swal.fire({
      title: "Are you sure you want to unban this user?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Unban",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          Swal.fire("User Unbanned.");
          await userUnBan(userId);
          window.location.reload();
        } catch (e) {
          Swal.fire(e.message);
        }
      }
    });
  };

  return (
    <>
      <ToastContainer />
      <div className="page-content">
        <Breadcrumbs title="Dashboard" breadcrumbItem="Edit Users" />
        {loading && <Loading loading={loading} />}
        {!loading && (
          <>
            <button
              type="button"
              className="btn btn-md btn-primary waves-effect waves-light mb-3"
              onClick={() => history.goBack()}
            >
              Back
            </button>

            <Form>
              <Row>
                <Col lg={6}>
                  <Card>
                    <CardBody>
                      <Row>
                        <Col lg={4}>Profile Image :</Col>
                        <Col lg={5}>
                          <div className="d-flex flex-column">
                            <img
                              className="img-fluid rounded mb-5"
                              style={{ width: "150px", height: "150px" }}
                              src={
                                temporaryProfileImage ||
                                updatedUserData?.profile_image?.url
                              }
                              alt={updatedUserData?.profile_image?.name}
                            />
                            <input
                              type="file"
                              name="profile_image"
                              onChange={handleImagePreview}
                            />
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg={6}>
                  <Card>
                    <CardBody>
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Username
                      </label>
                      <div className="col-md-10">
                        <input
                          defaultValue={updatedUserData.nickname || "-"}
                          onChange={handleUpdate}
                          className="form-control"
                          type="text"
                          name="nickname"
                        />
                      </div>
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Gender
                      </label>
                      <div className="col-md-10">
                        <select
                          className="p-2 form-control"
                          onChange={handleUpdate}
                          name="gender"
                        >
                          {genders.map((gender) => {
                            return (
                              <option
                                selected={
                                  updatedUserData.gender === gender.get("name")
                                }
                                value={gender.id}
                              >
                                {gender.get("name")}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Mail
                      </label>
                      <div className="col-md-10">
                        <input
                          defaultValue={updatedUserData.email || "-"}
                          onChange={handleUpdate}
                          className="form-control"
                          type="text"
                          name="email"
                        />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Form>
            <div className="text-center mt-4">
              <button
                type="button"
                className="btn btn-primary waves-effect waves-light"
                onClick={handleSubmit}
              >
                Submit
              </button>
              <button
                onClick={() => handleDeleteUser(updatedUserData?.objectId)}
                type="button"
                title="Delete User"
                className="btn btn-danger waves-effect waves-light ms-2"
              >
                <i className="fas fa-solid fa-trash"></i>
              </button>

              <button
                onClick={() => {
                  updatedUserData?.isBanned
                    ? handleUnban(updatedUserData?.objectId)
                    : handleBan(updatedUserData?.objectId);
                }}
                type="button"
                title={updatedUserData?.isBanned ? "Unban User" : "Ban User"}
                className="btn btn-danger waves-effect waves-light ms-2"
              >
                {updatedUserData?.isBanned ? (
                  <i className="fas fa-solid fa-lock-open"></i>
                ) : (
                  <i className="fas fa-solid fa-lock"></i>
                )}
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default UserDetail;
