import { useEffect, useState } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import Loading from "../../../components/Common/Loading";
import { getReportDetail } from "../../../service/parse-service";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";

const ReportDetails = () => {
  const [loading, setLoading] = useState(false);
  const [reportDetail, setReportDetails] = useState(null);

  const id =
    window.location.pathname.split("/")[
      window.location.pathname.split("/").length - 1
    ];

  useEffect(() => {
    setLoading(true);
    (async () => {
      try {
        const reportData = await getReportDetail(id);
        if (reportData) setReportDetails(reportData);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    })();
  }, [id]);

  return (
    <>
      <ToastContainer />
      <div className="page-content">
        <Breadcrumbs
          title="Dashboard"
          previousReport="Report"
          breadcrumbItem="Report Detail"
        />
        {loading && <Loading loading={loading} />}
        {!loading && reportDetail && (
          <>
            <Link to="/report">
              <button
                type="button"
                className="btn btn-md btn-primary waves-effect waves-light mb-3"
              >
                Back
              </button>
            </Link>
            <Row>
              <Col lg={5}>
                <Card>
                  <CardBody>
                    <Row className="mb-4 border-bottom">
                      <Col lg={4}>Reporting User :</Col>
                      <Col lg={8}>{reportDetail?.user?.nickname || "-"}</Col>
                    </Row>
                    <Row className="mb-4 border-bottom">
                      <Col lg={4}>Reported User :</Col>
                      <Col lg={8}>
                        {reportDetail?.reported_user_data?.nickname || "-"}
                      </Col>
                    </Row>
                    <Row className="mb-4 border-bottom">
                      <Col lg={4}>Reason :</Col>
                      <Col lg={8}>{reportDetail?.reason || "-"}</Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </>
        )}
      </div>
    </>
  );
};

export default ReportDetails;
