import { useState } from "react";
import { Tr, Td } from "react-super-responsive-table";

const MissedCallTr = ({ missedCall, history }) => {
  const [isHoverTutorName, setIsHoverTutorName] = useState(false);
  const [isHoverUserName, setIsHoverUserName] = useState(false);

  const isHoverNameStyles = {
    cursor: isHoverTutorName || isHoverUserName ? "pointer" : "default",
    textDecoration: isHoverTutorName || isHoverUserName ? "underline" : "none",
    color: isHoverTutorName || isHoverUserName ? "#007bff" : "",
  };

  return (
    <Tr>
      <Td
        onClick={() => {
          history.push(`/users/${missedCall.tutor?.objectId}`);
        }}
        onMouseEnter={() => setIsHoverTutorName(true)}
        onMouseLeave={() => setIsHoverTutorName(false)}
        style={isHoverTutorName ? isHoverNameStyles : {}}
      >
        {missedCall.tutor?.nickname}
      </Td>
      <Td
        onClick={() => {
          history.push(`/users/${missedCall.user?.objectId}`);
        }}
        onMouseEnter={() => setIsHoverUserName(true)}
        onMouseLeave={() => setIsHoverUserName(false)}
        style={isHoverUserName ? isHoverNameStyles : {}}
      >
        {missedCall.user?.nickname}
      </Td>
      <Td>{new Date(missedCall.updatedAt).toLocaleString()}</Td>
    </Tr>
  );
};

export default MissedCallTr;
