import { Pagination, PaginationItem, PaginationLink } from "reactstrap";

const TablePagination = ({
  dataPerPage,
  totalData,
  currentPage,
  setCurrentPage,
}) => {
  const pageNumbers = [];

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const getPagesCut = (pagesCount, pagesCutCount, currentPage) => {
    const ceiling = Math.ceil(pagesCutCount / 2);
    const floor = Math.floor(pagesCutCount / 2);

    if (pagesCount < pagesCutCount) {
      return { start: 1, end: pagesCount + 1 };
    } else if (currentPage >= 1 && currentPage <= ceiling) {
      return { start: 1, end: pagesCutCount + 1 };
    } else if (currentPage + floor >= pagesCount) {
      return { start: pagesCount - pagesCutCount + 1, end: pagesCount + 1 };
    } else {
      return { start: currentPage - ceiling + 1, end: currentPage + floor + 1 };
    }
  };

  const pagesCut = getPagesCut(
    Math.ceil(totalData / dataPerPage),
    5,
    currentPage
  );

  for (let i = pagesCut.start; i < pagesCut.end; i++) {
    pageNumbers.push(i);
  }

  const handlePrevious = () => {
    if (currentPage > 1) {
      paginate(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < Math.ceil(totalData / dataPerPage)) {
      paginate(currentPage + 1);
    }
  };

  const handleFirst = () => {
    paginate(1);
  };

  const handleLast = () => {
    paginate(Math.ceil(totalData / dataPerPage));
  };

  return (
    <div className="d-flex position-relative mt-3">
      <span className="position-absolute ps-3">
        Showing{" "}
        <span className="fw-bold">
          {currentPage * dataPerPage - dataPerPage + 1}
        </span>{" "}
        to{" "}
        <span className="fw-bold">
          {currentPage * dataPerPage > totalData
            ? totalData
            : currentPage * dataPerPage}
        </span>{" "}
        of <span className="fw-bold">{totalData}</span> entries
      </span>
      <Pagination className="me-auto ms-auto">
        <PaginationItem disabled={currentPage === 1}>
          <PaginationLink onClick={handleFirst}>First</PaginationLink>
        </PaginationItem>

        <PaginationItem disabled={currentPage === 1}>
          <PaginationLink onClick={handlePrevious}>Previous</PaginationLink>
        </PaginationItem>

        {pageNumbers.map((number) => (
          <PaginationItem key={number} active={currentPage === number}>
            <PaginationLink onClick={() => paginate(number)}>
              {number}
            </PaginationLink>
          </PaginationItem>
        ))}

        <PaginationItem
          disabled={
            currentPage === Math.ceil(totalData / dataPerPage) ||
            totalData === 0
          }
        >
          <PaginationLink onClick={handleNext}> Next</PaginationLink>
        </PaginationItem>

        <PaginationItem
          disabled={
            currentPage === Math.ceil(totalData / dataPerPage) ||
            totalData === 0
          }
        >
          <PaginationLink onClick={handleLast}>Last</PaginationLink>
        </PaginationItem>
      </Pagination>
    </div>
  );
};

export default TablePagination;
