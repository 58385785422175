import { Container, Row, Col } from "reactstrap";

const Footer = () => {
  return (
    <>
      <footer className="footer text-center">
        <Container fluid={true}>
          <Row>
            <Col sm={12}>{new Date().getFullYear()} © Langugo.</Col>
          </Row>
        </Container>
      </footer>
    </>
  );
};

export default Footer;
