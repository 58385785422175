import { useEffect, useState } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import Loading from "../../../components/Common/Loading";
import { getTutorEarningReportDetail } from "../../../service/parse-service";
import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import { ToastContainer } from "react-toastify";

const TutorEarningDetail = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    columns: [
      {
        label: "Start Time",
        field: "startTime",
        sort: "asc",
        width: 150,
      },
      {
        label: "End Time",
        field: "endTime",
        sort: "asc",
        width: 150,
      },
      {
        label: "Amount",
        field: "amount",
        sort: "asc",
        width: 270,
      },
      {
        label: "Reason",
        field: "reason",
        sort: "asc",
        width: 100,
      },
    ],
  });

  const id =
    window.location.pathname.split("/")[
      window.location.pathname.split("/").length - 1
    ];

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        let mappedResult = [];
        let resultArray = [];
        const tutorData = await getTutorEarningReportDetail(id);
        mappedResult = tutorData.map((tutor) => {
          tutor.amount.forEach((_, i) => {
            resultArray.push({
              startTime: new Date(tutor?.startTime?.[i]?.iso)?.toLocaleString(),
              endTime: new Date(tutor?.endTime?.[i]?.iso)?.toLocaleString(),
              amount: isNaN(tutor?.amount?.[i])
                ? ""
                : `$ ${tutor?.amount?.[i]}`,
              reason: tutor?.reason?.[i]?.toUpperCase(),
            });
          });
          return resultArray;
        });
        for (const result of mappedResult) {
          setData((prevState) => ({
            ...prevState,
            rows: result,
          }));
        }
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    })();
  }, [id]);

  return (
    <>
      <ToastContainer />
      <div className="page-content">
        <Breadcrumbs
          title="Dashboard"
          previousTutorEarning="Tutor Earning"
          breadcrumbItem="Tutor Earning Detail"
        />
        <Link to="/tutor-earning">
          <button
            type="button"
            className="btn btn-md btn-primary waves-effect waves-light mb-3"
          >
            Back
          </button>
        </Link>
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                {loading && <Loading loading={loading} />}
                {!loading && (
                  <MDBDataTable hover responsive striped bordered data={data} />
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default TutorEarningDetail;
