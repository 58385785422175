import { useState } from "react";
import { Tr, Td } from "react-super-responsive-table";
import { Link } from "react-router-dom";

const TutorEarningTr = ({ tutorEarning, currentUserData, history }) => {
  let tip = 0;
  let conversation = 0;

  tutorEarning.reason?.forEach((item, index) => {
    if (item === "Tip") {
      tip += tutorEarning?.amount[index];
    } else {
      conversation += tutorEarning?.amount[index];
    }
  });

  const [isHoverName, setIsHoverName] = useState(false);

  const handleMouseEnter = () => {
    setIsHoverName(true);
  };

  const handleMouseLeave = () => {
    setIsHoverName(false);
  };

  const isHoverNameStyles = {
    cursor: isHoverName ? "pointer" : "default",
    textDecoration: isHoverName ? "underline" : "none",
    color: isHoverName ? "#007bff" : "",
  };

  return (
    <Tr>
      <Td
        onClick={() => {
          history.push(`/users/${tutorEarning.tutor_id}`);
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={isHoverNameStyles}
      >
        {tutorEarning.fullname}
      </Td>
      <Td>{new Date(tutorEarning.createdAt).toLocaleString()}</Td>
      <Td>{tutorEarning.totalSeconds}</Td>
      {currentUserData?.get("username") === "admin" && (
        <Td>{tutorEarning.totalMinutes}</Td>
      )}
      <Td>$ {tip.toFixed(2)}</Td>
      <Td>$ {conversation.toFixed(2)}</Td>
      <Td>$ {tutorEarning.totalAmount.toFixed(2)}</Td>

      <Td>
        <Link to={`/tutor-earning-detail/${tutorEarning.objectId}`}>
          <button
            type="button"
            title="Detail"
            className="btn btn-md btn-primary waves-effect waves-light d-flex justify-content-center align-items-center "
          >
            <i className="bx bxs-show"></i>
          </button>
        </Link>
      </Td>
    </Tr>
  );
};

export default TutorEarningTr;
