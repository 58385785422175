import { useEffect, useState } from "react";
import { Table, Thead, Tbody, Tr, Th } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import Loading from "../../../components/Common/Loading";
import { fetchCurrentUser } from "../../../service/parse-service";
import ActivityTr from "./ActivityTr";

const ActivityTable = ({
  activityData,
  loading,
  handleOrder,
  order,
  history,
}) => {
  const [currentUserData, setCurrentUserData] = useState(null);

  useEffect(() => {
    fetchCurrentUser().then((currentUserData) => {
      setCurrentUserData(currentUserData);
    });
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="table-rep-plugin">
        <div
          className="table-responsive mb-0 "
          data-pattern="priority-columns"
          style={{
            position: "relative",
            height: "70vh",
          }}
        >
          <Table className="table table-striped table-bordered">
            <Thead>
              <Tr>
                <Th>Tutor Name</Th>
                <Th>Username</Th>
                <Th
                  onClick={() => handleOrder("startTime")}
                  style={{ cursor: "pointer" }}
                >
                  Conversation Date{" "}
                  <div className="d-inline-block">
                    <i
                      className="dripicons-arrow-thin-up"
                      style={{
                        opacity:
                          order.column === "startTime" &&
                          order.direction === "desc"
                            ? 1
                            : 0.6,
                      }}
                    ></i>
                    <i
                      className="dripicons-arrow-thin-down"
                      style={{
                        opacity:
                          order.column === "startTime" &&
                          order.direction === "asc"
                            ? 1
                            : 0.6,
                      }}
                    ></i>
                  </div>
                </Th>
                <Th
                  onClick={() => handleOrder("total_seconds")}
                  style={{ cursor: "pointer" }}
                >
                  Conversation Time (Sec){" "}
                  <div className="d-inline-block">
                    <i
                      className="dripicons-arrow-thin-up"
                      style={{
                        opacity:
                          order.column === "total_seconds" &&
                          order.direction === "desc"
                            ? 1
                            : 0.6,
                      }}
                    ></i>
                    <i
                      className="dripicons-arrow-thin-down"
                      style={{
                        opacity:
                          order.column === "total_seconds" &&
                          order.direction === "asc"
                            ? 1
                            : 0.6,
                      }}
                    ></i>
                  </div>
                </Th>
                {currentUserData?.get("username") === "admin" && (
                  <Th
                    onClick={() => handleOrder("total_minutes")}
                    style={{ cursor: "pointer" }}
                  >
                    Total Coins Spent{" "}
                    <div className="d-inline-block">
                      <i
                        className="dripicons-arrow-thin-up"
                        style={{
                          opacity:
                            order.column === "total_minutes" &&
                            order.direction === "desc"
                              ? 1
                              : 0.6,
                        }}
                      ></i>
                      <i
                        className="dripicons-arrow-thin-down"
                        style={{
                          opacity:
                            order.column === "total_minutes" &&
                            order.direction === "asc"
                              ? 1
                              : 0.6,
                        }}
                      ></i>
                    </div>
                  </Th>
                )}
                <Th
                  onClick={() => handleOrder("amount")}
                  style={{ cursor: "pointer" }}
                >
                  Tip Amount{" "}
                  <div className="d-inline-block">
                    <i
                      className="dripicons-arrow-thin-up"
                      style={{
                        opacity:
                          order.column === "amount" &&
                          order.direction === "desc"
                            ? 1
                            : 0.6,
                      }}
                    ></i>
                    <i
                      className="dripicons-arrow-thin-down"
                      style={{
                        opacity:
                          order.column === "amount" && order.direction === "asc"
                            ? 1
                            : 0.6,
                      }}
                    ></i>
                  </div>
                </Th>
                <Th
                  onClick={() => handleOrder("amount")}
                  style={{ cursor: "pointer" }}
                >
                  Tutor Progress Payment{" "}
                  <div className="d-inline-block">
                    <i
                      className="dripicons-arrow-thin-up"
                      style={{
                        opacity:
                          order.column === "amount" &&
                          order.direction === "desc"
                            ? 1
                            : 0.6,
                      }}
                    ></i>
                    <i
                      className="dripicons-arrow-thin-down"
                      style={{
                        opacity:
                          order.column === "amount" && order.direction === "asc"
                            ? 1
                            : 0.6,
                      }}
                    ></i>
                  </div>
                </Th>
              </Tr>
            </Thead>
            {loading ? (
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: 500,
                  height: 500,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Loading />
              </div>
            ) : (
              <Tbody>
                {activityData?.map((activity) => (
                  <ActivityTr
                    key={activity.objectId}
                    activity={activity}
                    currentUserData={currentUserData}
                    history={history}
                  />
                ))}
              </Tbody>
            )}
          </Table>
        </div>
      </div>
    </>
  );
};

export default ActivityTable;
