import { useState } from "react";
import { Tr, Td } from "react-super-responsive-table";

const SalesReportTr = ({ productLog, history }) => {
  const [isHoverName, setIsHoverName] = useState(false);

  const handleMouseEnter = () => {
    setIsHoverName(true);
  };

  const handleMouseLeave = () => {
    setIsHoverName(false);
  };

  const isHoverNameStyles = {
    cursor: isHoverName ? "pointer" : "default",
    textDecoration: isHoverName ? "underline" : "none",
    color: isHoverName ? "#007bff" : "",
  };
  return (
    <Tr>
      <Td>{new Date(productLog.createdAt).toLocaleString()}</Td>
      <Td
        onClick={() => {
          history.push(`/users/${productLog.user.objectId}`);
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={isHoverNameStyles}
      >
        {productLog.user && productLog.user.nickname}
      </Td>
      <Td>{productLog.productType}</Td>
      <Td>$ {productLog.price}</Td>
    </Tr>
  );
};

export default SalesReportTr;
