import { useEffect, useState } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import Loading from "../../../components/Common/Loading";
import { getTutor } from "../../../service/parse-service";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import ImageModal from "../../../components/Modal/ImageModal";


const PendingTutorDetails = () => {
  const [loading, setLoading] = useState(false);
  const [tutor, setTutor] = useState(null);
  const [imageModal, setImageModal] = useState({
    isOpen: false,
    image: "",
  });

  const id =
    window.location.pathname.split("/")[
      window.location.pathname.split("/").length - 1
    ];

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const tutorData = await getTutor(id);
        if (tutorData) setTutor(tutorData);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    })();
  }, [id]);

  return (
    <>
      <ToastContainer />
      <div className="page-content">
      <ImageModal imageModal={imageModal} setImageModal={setImageModal} />
        <Breadcrumbs
          title="Dashboard"
          previousTutorPending="Pending Tutors"
          breadcrumbItem="Pending Tutor Detail"
        />
        {loading && <Loading loading={loading} />}
        {!loading && tutor && (
          <>
            <Link to="/pending-tutors">
              <button
                type="button"
                className="btn btn-md btn-primary waves-effect waves-light mb-3"
              >
                Back
              </button>
            </Link>
            <Row>
              <Col lg={5}>
                <Card>
                  <CardBody>
                    <Row className="mb-4 border-bottom">
                      <Col lg={4}>Profile Image :</Col>
                      <Col lg={8}>
                        <img
                          src={
                            tutor?.photo?.url ||
                            `https://ui-avatars.com/api/?name=${tutor.fullname}`
                          }
                          alt={tutor.fullname}
                          className="img-fluid rounded-circle"
                          onClick={() =>
                            setImageModal({
                              isOpen: true,
                              image: tutor?.photo?.url,
                            })
                          }
                          style={{ width: "100px", height: "100px",cursor:"pointer" }}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-4 border-bottom">
                      <Col lg={4}>Username :</Col>
                      <Col lg={8}>{tutor.fullname || "-"}</Col>
                    </Row>
                    <Row className="mb-4 border-bottom">
                      <Col lg={4}>Email :</Col>
                      <Col lg={8}>{tutor.mail || "-"}</Col>
                    </Row>
                    <Row className="mb-4 border-bottom">
                      <Col lg={4}>Known Languages :</Col>
                      <Col lg={8}>
                        {tutor.speaking_languages_JSON
                          ?.map((language) => language.name)
                          .join(", ") || "-"}
                      </Col>
                    </Row>
                    <Row className="mb-4 border-bottom">
                      <Col lg={4}>Mobile :</Col>
                      <Col lg={8}>{tutor.mobile || "-"}</Col>
                    </Row>
                    <Row className="mb-4 border-bottom">
                      <Col lg={4}>Birth Date :</Col>
                      <Col lg={8}>{tutor.dob || "-"}</Col>
                    </Row>
                    <Row className="mb-4 border-bottom">
                      <Col lg={4}>Gender :</Col>
                      <Col lg={8}>
                        {tutor.gender === "female" ? "Female" : "Male" || "-"}
                      </Col>
                    </Row>
                    <Row className="mb-4 border-bottom">
                      <Col lg={4}>Bank Information :</Col>
                      <Col lg={8}>{tutor.paypal || "-"}</Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </>
        )}
      </div>
    </>
  );
};

export default PendingTutorDetails;
