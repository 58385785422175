import { useEffect, useState } from "react";
import ReactEcharts from "echarts-for-react";
import { getPlatformCount } from "../../../service/parse-service";

const PiePlatform = () => {

  const [platformCount, setPlatformCount] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const platformData = await getPlatformCount();
      if (platformData) setPlatformCount(platformData);
    };
    fetchData();
  }, []);


  const getOption = () => {
    return {
      toolbox: {
        show: false,
      },
      tooltip: {
        trigger: "item",
        formatter: "{a} <br/>{b} : {c} ({d}%)",
      },
      legend: {
        orient: "vertical",
        left: "left",
        data: ["Android", "IOS", "Web", "Other"],
        textStyle: {
          color: ["#74788d"],
        },
      },
      color: ["#02a499", "#f8b425", "#ec4561", "#38a4f8", "#3c4ccf"],
      series: [
        {
          name: "Toplam",
          type: "pie",
          radius: "55%",
          center: ["50%", "60%"],
          data: [
            { value: (platformCount?.android || 0), name: "Android" },
            { value: (platformCount?.iOS || 0), name: "IOS" },
            { value: (platformCount?.web || 0), name: "Web" },
            { value: (platformCount?.unknown || 0), name: "Other" },
          ],
          itemStyle: {
            emphasis: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
    }
  }

  return (
    <>
      <ReactEcharts style={{ height: "350px" }} option={getOption()} />
    </>
  )
}

export default PiePlatform
