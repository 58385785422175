import { useState } from "react";
import { Tr, Td } from "react-super-responsive-table";
import { Link } from "react-router-dom";

const PendingTutorTr = ({
  pendingTutor,
  handleAssignAsTutor,
  handleRejectTutor,
  history,
}) => {
  const [isHoverName, setIsHoverName] = useState(false);

  const handleMouseEnter = () => {
    setIsHoverName(true);
  };

  const handleMouseLeave = () => {
    setIsHoverName(false);
  };

  const isHoverNameStyles = {
    cursor: isHoverName ? "pointer" : "default",
    textDecoration: isHoverName ? "underline" : "none",
    color: isHoverName ? "#007bff" : "",
  };

  return (
    <Tr key={pendingTutor.objectId}>
      <Td>{new Date(pendingTutor.createdAt).toLocaleString()}</Td>
      <Td
        onClick={() => {
          history.push(`/users/${pendingTutor.user?.objectId}`);
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={isHoverName ? isHoverNameStyles : {}}
      >
        {pendingTutor.user?.nickname}
      </Td>
      <Td>{pendingTutor.fullname}</Td>
      <Td>{pendingTutor.mail}</Td>
      <Td>{pendingTutor.mobile}</Td>
      <Td>{pendingTutor.dob}</Td>
      <Td>{pendingTutor?.gender === "female" ? "F" : "M"}</Td>
      <Td>
        {pendingTutor?.speaking_languages_JSON
          ?.map((language) => language.name)
          .join(", ")}
      </Td>

      <Td>
        <div className="d-flex">
          <Link to={`/pending-tutors/${pendingTutor.objectId}`}>
            <button
              type="button"
              title="Detail"
              className="btn btn-md btn-primary waves-effect waves-light d-flex justify-content-center align-items-center"
            >
              <i className="bx bxs-show font-size-10 "></i>
            </button>
          </Link>
          <button
            type="button"
            title="Confirm"
            className="btn btn-md btn-primary waves-effect waves-light d-flex justify-content-center align-items-center ms-2"
            onClick={() => handleAssignAsTutor(pendingTutor.user.objectId)}
          >
            <i className="fas fa-check"></i>
          </button>
          <button
            type="button"
            title="Reject"
            key={pendingTutor.user.objectId}
            onClick={() => handleRejectTutor(pendingTutor.user.objectId)}
            className="btn btn-md btn-danger waves-effect waves-light d-flex justify-content-center align-items-center ms-2"
          >
            <i className="fas fa-solid fa-trash"></i>
          </button>
        </div>
      </Td>
    </Tr>
  );
};

export default PendingTutorTr;
